import "./style.scss";

const Loader = () => {
  return (
    <>
    <div className="loader-background"></div>
    <div className="loader-wrapper">
      <div class="loader">
        <div class="loader-square"></div>
        <div class="loader-square"></div>
        <div class="loader-square"></div>
        <div class="loader-square"></div>
        <div class="loader-square"></div>
        <div class="loader-square"></div>
        <div class="loader-square"></div>
      </div>
    </div>
    </>
  );
};

export default Loader;
