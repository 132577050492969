import { useEffect, useState } from "react";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { api_path } from "./constants";
import Test from "./components/common/modal/test";
import Loader from "./components/common/loader";

function Payment(props) {
  const [stripePromise, setStripePromise] = useState(null);
  const [clientSecret, setClientSecret] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetch(`${api_path}/config`).then(async (r) => {
      const { publishableKey } = await r.json();
      setStripePromise(loadStripe(publishableKey));
    });
  }, []);

  useEffect(() => {
    if (!!props?.data?.cost) {
      try {
        fetch(`${api_path}/create-payment-intent`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            currency: "AUD",
            amount: props?.data?.cost,
          }),
        }).then(async (result) => {
          var { clientSecret } = await result.json();
          setClientSecret(clientSecret);
          setIsLoading(false);
        });
      } catch (error) {
        console.log(error, "an error occurred");
      }
    }
  }, [props?.data?.cost]);

  return (
    <>
      {isLoading && <Loader />}
      {clientSecret && stripePromise && (
        <div className={props.data.paymentMethod !== "card" ? "d-none" : "transitionIn-animation"}>
          <Elements stripe={stripePromise} options={{ clientSecret }}>
            <Test
              data={props.data}
              clicked={props.clicked}
              close={props.close}
            />
          </Elements>
        </div>
      )}
    </>
  );
}

export default Payment;
