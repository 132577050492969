import React, { lazy, Suspense } from "react";
import "./style.scss";
import CustomNavbar from "./components/common/navbar/index2";
import Working from "./components/dashboards/working";
import Advantages from "./components/dashboards/advantages";
import SecondaryFooter from "./components/common/SecondaryFooter";
import { Route, Routes } from "react-router-dom";
import Dashboard from "./components/dashboards";
import BookingForm from "./components/dashboards/bookingForm";
import AboutUs from "./components/dashboards/aboutUs";
import ServicesOffer from "./components/dashboards/services";
import NewPage from "./components/common/newPage";
import Thankyou from "./components/dashboards/thankyou/index.js";
import {
  AIRPORT_TRANSFER,
  CITY_TRANSFER,
  CORPORATE_TRANSFER,
  HOURLY_TRANSFER,
  LUXURY_TRANSFER,
  P2P_TRANSFER,
} from "./constants";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import TermsAndConditions from "./components/dashboards/TermsAndConditions";

import Pagenotfound from "./components/dashboards/404";
import Admin from "./components/dashboards/admin";
// import Lead from "./components/dashboards/Lead";
import Quote from "./components/dashboards/quotation";


// const CustomNavbar = lazy(() => import('./components/common/navbar/index2'));


function App() {
  // console.log = console.error = console.warn = () => {};
  // const db = firebase.firestore()

  const navItems = [
    {
      name: "HOME",
      url: "/",
    },
    {
      name: "ABOUT US",
      url: "/aboutus",
    },
    {
      name: "SERVICE",
      url: "/services",
      child: [
        { name: "Airport Transfer", url: "/airporttransport" },
        { name: "Corporate", url: "/corporatetransport" },
        { name: "P2P Transport", url: "/p2ptransport" },
        { name: "Hourly Transport", url: "/hourlytransport" },
        { name: "City Transport", url: "/citytransport" },
        { name: "Luxury Transport", url: "/luxurytransport" },
      ],
    },
    {
      name: "ADVANTAGES",
      url: "/advantages",
    },
  ];
  // ["HOME", "SERVICE", "ADVANTAGES", "HOW IT WORKS"];
  return (
    <div className="App">
      <ipnut />
      {/* 
      <Suspense fallback={<div>Loading...</div>}>
      <CustomNavbar items={navItems} />
      </Suspense> */}

      <CustomNavbar items={navItems} />

      <Routes>
        <Route exact path="/" element={<Dashboard />} />
        <Route path="/services" element={<ServicesOffer />} />
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/advantages" element={<Advantages />} />
        <Route path="/working" element={<Working />} />
        <Route path="/book-chauffeur" element={<BookingForm />} />
        <Route path="/thankyou" element={<Thankyou />} />
        <Route path="*" element={<Pagenotfound />} />
        <Route path="/admin" element={<Admin />} />
        <Route path="/quotation" element={<Quote />} />
        <Route
          path="/airporttransport"
          element={
            <NewPage
              title="Perth Airport Transfer Chauffeur Services | HR Chauffeur"
              discription="HR Chauffeur Drive is ready for your next Perth Airport transfer (PER). Book a Private chauffeured car airport limousine hire in Perth today."
              url="https://www.hr-chauffeur.com.au/airporttransport"
              heading="Airport"
              subheading="Transfers"
              nextSubHeading="Book Your Airport Transfer Today!"
              paragraphs={AIRPORT_TRANSFER}
            />
          }
        />
        <Route
          path="/corporatetransport"
          element={
            <NewPage
              title="Perth Corporate Transfers Chauffeur services | HR Chauffeur"
              discription="Perth Corporate Transfers, where we specialize in providing the most reliable and professional corporate transportation Chauffeur in town. Contact HR Chauffeur"
              url="https://www.hr-chauffeur.com.au/corporatetransport"
              heading="Corporate"
              subheading="Transportation"
              nextSubHeading="Book Your Corporate Transfer Today!"
              paragraphs={CORPORATE_TRANSFER}
            />
          }
        />
        <Route
          path="/p2ptransport"
          element={
            <NewPage
              title="Point-to-Point Transport & Pick and Drop Service in Perth"
              discription="Experience hassle-free travel in Perth with our reliable point-to-point transport and pick and drop services. We ensure convenience for your journeys."
              url="https://www.hr-chauffeur.com.au/p2ptransport"
              heading="Point-to-Point"
              subheading="Transfers"
              nextSubHeading="Book Your Point to point Transfer Today!"
              paragraphs={P2P_TRANSFER}
            />
          }
        />
        <Route
          path="/hourlytransport"
          element={
            <NewPage
              title="Hourly Private Chauffeur Services HR Chauffeur Cost per Hour"
              discription="Looking for Hourly Private Chauffeur Services? Discover our Cost per Hour Chauffeur Services. Enjoy a stress-free ride with experienced chauffeurs."
              url="https://www.hr-chauffeur.com.au/hourlytransport"
              heading="Hourly"
              subheading="Services"
              nextSubHeading="Book Your Hourly transfer Today!"
              paragraphs={HOURLY_TRANSFER}
            />
          }
        />
        <Route
          path="/citytransport"
          element={
            <NewPage
              title="Car Hire for City Tours in Perth | City Tour Chauffeur Car Services"
              discription="Explore Perth with ease! We offer reliable car hire services for tours in Perth, providing top-quality tour Chauffeur cars to enhance your travel experience."
              url="https://www.hr-chauffeur.com.au/citytransport"
              heading="City"
              subheading="Tours"
              nextSubHeading="Book Your City transfer Today!"
              paragraphs={CITY_TRANSFER}
            />
          }
        />
        <Route
          path="/luxurytransport"
          element={
            <NewPage
              title="Luxury Car Chauffeur Perth | Car Hire & Transport Services"
              discription="Indulge in luxury travel with our chauffeur-driven cars and top-notch luxury car hire services in Perth. Experience the epitome of style, comfort, and class."
              url="https://www.hr-chauffeur.com.au/luxurytransport"
              heading="Luxury"
              nextSubHeading="Book Your Luxury transfer Today!"
              subheading="Transportation"
              paragraphs={LUXURY_TRANSFER}
            />
          }
        />
        <Route
          path="/terms-and-conditions"
          element={
            <TermsAndConditions

              heading="Terms"
              nextSubHeading="Usage Agreement"
              subheading="And Conditions"
            />
          }
        />
      </Routes>
      <FloatingWhatsApp
        phoneNumber="+61455031641"
        accountName="HR Chauffeur Service"
      />
      <SecondaryFooter copyright_text="Copyright 2022-2023 | All Rights Reserved" />
    </div>
  );
}

export default App;
