import emailjs from "@emailjs/browser";

export const ContactUs = (params, templateId="client_template") => {

  emailjs.send("service_e1utxtl", templateId, params, "464US7RoCG0JyIDxh").then(
    (result) => {
      console.log(result.text);
    },
    (error) => {
      console.log(error.text, "test error");
    }
  );

  
};