
import "./style.scss";
import { HIRE } from '../../../constants';
import { Link } from "react-router-dom";

const Card = (props) => {

    const { data:{imgSrc, heading, content, } } = props;
    return (
        <div className="card-wrapper card_services" id="card_services_2">
            
            <img src={imgSrc} alt={heading} />
            <p className="heading">{heading}</p>
            <p className="content">{content}</p>
            <div className="button-box" >
            <button className="outline_btn hire_btn hire_btn_card btn_know" style={{ textAlign: 'center', zIndex:"99", margin:'10px'}}>KNOW MORE</button>
            <Link
          name={HIRE}
          className="outline_btn hire_btn hire_btn_card"
          to="/book-chauffeur"
          style={{  textAlign: 'center', zIndex:"99", margin:'10px' }}
        >
          {HIRE}
        </Link>
            </div>
        </div>
    )
}

export default Card