import Accordion from 'react-bootstrap/Accordion';
import './style.scss'

function FlushExample() {
  return (
    <Accordion defaultActiveKey="0" flush>
      <Accordion.Item eventKey="0">
        <Accordion.Header>What is chauffeur drive?</Accordion.Header>
        <Accordion.Body>
        A chauffeur is someone employed to look after the needs of the passenger as well as the operation of the motor vehicle. A chauffeur is an experienced, licensed professional. 

        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>What is a luxury chauffeur?</Accordion.Header>
        <Accordion.Body>
        Chauffeurs are highly trained drivers, hired only to drive VIP clients in luxury cars. A chauffeur service requires a number of protocols and responsibilities which the chauffeur has to follow in order to receive elite clients.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header>Why hire chauffeur service?</Accordion.Header>
        <Accordion.Body>
        Chauffeurs are there to deliver a professional service for individuals who need transportation, whether it be for an event, for business, travelling to the airport, or for sightseeing around a city.
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

export default FlushExample;