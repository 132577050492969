import React, { Component } from "react";
import {
  withGoogleMap,
  withScriptjs,
  GoogleMap,
  DirectionsRenderer,
} from "react-google-maps";
class Map extends Component {
  state = {
    directions: null,
  };

  componentDidMount() {
    const { start, end } = this.props;
    let google = window.google;
    const directionsService = new google.maps.DirectionsService();

    const origin = start;
    const destination = end;

    directionsService.route(
      {
        origin: origin,
        destination: destination,
        travelMode: google.maps.TravelMode.DRIVING,
        waypoints: [
          {
            location: new google.maps.LatLng(start.lat, start.lng),
          },
          {
            location: new google.maps.LatLng(end.lat, end.lng),
          },
        ],
      },
      (result, status) => {
        if (status === google.maps.DirectionsStatus.OK) {
          this.setState({
            directions: result,
          });
        } else {
          console.error(`error fetching directions ${result}`);
        }
      }
    );
  }

  render() {
    const GoogleMapExample = withGoogleMap((props) => (
      <GoogleMap defaultCenter={{ lat: 6.5244, lng: 3.3792 }} defaultZoom={55}>
        <DirectionsRenderer directions={this.state.directions} />
      </GoogleMap>
    ));

    return (
      <div className="maps-wrapper">
        <GoogleMapExample
          containerElement={<div style={{ height: `300px`, width: "300px" }} />}
          mapElement={<div style={{ height: `100%` }} />}
        />
      </div>
    );
  }
}

export default Map;
