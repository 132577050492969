import { ADVANTAGE, ADVANTAGE_CARD_LIST, WHAT_ARE_THE } from "../../../constants"
import Card from "../../common/card"
import Header from "../../common/componentHeader"
import { Link } from "react-router-dom";
import { HIRE } from "../../../constants";
import "./style.scss";
import { Helmet } from 'react-helmet';


const Advantages = () => {
    return (
        <div className='h_100 w_100 services_section transitionIn-animation pb_20' id="advantages">
     <Helmet>
        <title>Advantage of Chauffeur Service in Perth - HR Chauffeur</title>
        <meta name="description" content="Discover the best private car rental and chauffeur service in Perth. Choose HR Chauffeur car rental company and enjoy premium chauffeur-driven experiences." />
        <link rel="canonical" href="https://www.hr-chauffeur.com.au/advantages" />
     </Helmet>

        
        <div className="head-background W_100">
      
 <Header heading={ADVANTAGE} secondaryHeading={WHAT_ARE_THE}  subHeading={ADVANTAGE} />
 </div>
 <div className='w_80 p_20 '>
        <div className="advantage_card_wrapper">
        { ADVANTAGE_CARD_LIST.map(item=> (
            <Card data={item}/>
        ))}
        </div>
        <div className="advantage-card-box">
        <Link
                name={HIRE}
                className="outline_btn hire_btn hire_btn_card"
                to="/book-chauffeur"
              >
                {HIRE}
        </Link>
        </div>
        </div>
        </div>
    )
}

export default Advantages