import { Link } from "react-router-dom";
import { HIRE } from "../../../constants";
import "./style.scss";

const Card = (props) => {

    const { data:{imgSrc, heading, content} } = props;
    return (
        <div className="card-wrapper">
            {imgSrc}
            <p className="heading">{heading}</p>
            <p className="content">{content}</p>
            {/* <Link
                name={HIRE}
                className="outline_btn hire_btn hire_btn_card"
                to="/book-chauffeur"
              >
                {HIRE}
              </Link> */}
        </div>
    )
}

export default Card