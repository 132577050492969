import LocationSearchInput from "../../../common/GoogleMap";
import LocationIcon from "@rsuite/icons/Location";
import "./style.scss";
import DateRange from "../../../common/dateRangePicker";
import moment from "moment";
import CustomInput from "../../../common/customInput";
import { SelectPicker } from "rsuite";
import CounterInput from "react-counter-input";
import {
  PRICINGTYPE_OPTIONS,
  TRIPOPTION_OBJECT,
  TRIPOPTION_SPECIFIC,
} from "../../../../constants";

const Step1 = (props) => {
  const {
    handleChange,
    personalData = "",
    setPersonalData,
    handleSelectStart,
    startAddress = "",
    setStartAddress,
    handleSelectEnd,
    endAddress = "",
    setEndAddress,
    setCount,
    handlePricingChange,
  } = props;

  const CurrentLocation = () => {
    navigator.geolocation.getCurrentPosition(function (position) {
      fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${position.coords.latitude},${position.coords.longitude}&key=AIzaSyBO1EhOYGsrG7Q7h7ubIIsvdMmpuHCY2YA`
      )
        .then((res) => res.json())
        .then(
          (result) => {
            setStartAddress({
              name: result.results[0].formatted_address,
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            });
            setPersonalData((personalData) => ({
              ...personalData,
              startLocation: {
                name: result.results[0].formatted_address,
                lat: position.coords.latitude,
                lng: position.coords.longitude,
              },
            }));
          },

          (error) => {
            // this.setState({
            // isLoaded: true,
            // error
            // });
          }
        );
    });
  };

  return (
    <div className="step1-wrapper">
      <div className="step_child step1-left border-right">
        <p className="steps-heading">Location Details</p>
        <div className="input-container">
          <p>Start Location</p>
          <LocationSearchInput
            handleSelect={handleSelectStart}
            placeHolder="Enter Your Start Location"
            className="hasButton"
            address={startAddress}
            personalData={personalData["startLocation"]}
            handleChange={(address) =>
              setStartAddress({ name: address, lat: 0, lng: 0 })
            }
          />
          <button onClick={CurrentLocation}>
            <LocationIcon color="white" />
          </button>
        </div>
        <div className="input-container">
          <p>End Location</p>
          <LocationSearchInput
            handleSelect={handleSelectEnd}
            placeHolder="Enter Your End Location"
            personalData={personalData["endLocation"]}
            address={endAddress}
            handleChange={(address) =>
              setEndAddress({ name: address, lat: 0, lng: 0 })
            }
          />
        </div>
        <div className="input-radio-container">
          <p>Options</p>
          {/* <div className="radio-container" onChange={handleChange}>
            <label>
              <input
                type="radio"
                value="oneWay"
                name="tripOption"
                checked={personalData["tripOption"] === "oneWay"}
              />{" "}
              <span>One Way</span>
            </label>
            <label>
              <input
                type="radio"
                value="return"
                name="tripOption"
                checked={personalData["tripOption"] === "return"}
              />{" "}
              Return
            </label>
            <label>
              <input
                type="radio"
                value="hourly"
                name="tripOption"
                checked={personalData["tripOption"] === "hourly"}
              />{" "}
              Hourly
            </label>
          </div> */}
          <div className="radio-container" onChange={handleChange}>
            {TRIPOPTION_SPECIFIC[personalData["pricingType"]?.value].map(
              (item) => {
                return (
                  <label>
                    <input
                      type="radio"
                      value={item}
                      name="tripOption"
                      checked={personalData["tripOption"] === item}
                    />{" "}
                    {TRIPOPTION_OBJECT[item]}
                  </label>
                );
              }
            )}
          </div>
          {/* <LocationSearchInput
            handleSelect={handleSelectEnd}
            placeHolder="Enter Your End Location"
            personalData={personalData["endLocation"]}
            address={endAddress}
            handleChange={(address) =>
              setEndAddress({ name: address, lat: 0, lng: 0 })
            }
          /> */}
        </div>
      </div>

      <div className="step_child step1-center border-right">
        <p className="steps-heading">Date & Time</p>
        <div className="date-wrapper">
          <p>Pickup Date & Time</p>
          <DateRange
            date={personalData["date"]}
            setDate={(picker) =>
              setPersonalData((personalData) => ({
                ...personalData,
                date: moment(picker).format("MM/DD/YYYY HH:mm"),
              }))
            }
            format="DD/MM/YYYY HH:mm A"
          />
        </div>
        {personalData["tripOption"] === "return" && (
          <div className="date-wrapper">
            <p>Return Date & Time</p>
            <DateRange
              date={personalData["returnDate"]}
              setDate={(picker) =>
                setPersonalData((personalData) => ({
                  ...personalData,
                  returnDate: moment(picker).format("MM/DD/YYYY HH:mm"),
                }))
              }
              format="DD/MM/YYYY HH:mm A"
            />
          </div>
        )}
        {personalData["pricingType"]?.value === "airport" && (
        <div className="input-container">
          <p>Flight Number</p>
          <CustomInput
            onChange={handleChange}
            value={personalData["flight"]}
            name={"flight"}
            placeholder="Flight Number"
            type="text"
            inputName="Flight Number"
            regex={/\/*/}
          />
        </div>
        )}
        <div className="input-container color-white">
          <p>Baby Seat</p>
          <CounterInput
            min={0}
            max={2}
            className="color-white"
            onCountChange={(count) => setCount({ value: count })}
          />
        </div>
      </div>
      <div className="step_child step1-last ">
        <p className="steps-heading">Other Details</p>
        <div className="radio-container">
          <p>Pricing Type</p>
          <div className="input-container">
            <SelectPicker
              placeholder="Select Pricing Type"
              name="pricingType"
              onChange={handlePricingChange}
              value={personalData["pricingType"]?.value}
              data={PRICINGTYPE_OPTIONS}
              cleanable={false}
              style={{ width: 300 }}
            />
          </div>
        </div>
        {personalData["tripOption"] === "hourly" && (
          <div className="input-container">
            <p>Hours</p>
            <CustomInput
              onChange={handleChange}
              value={personalData["hours"]}
              name={"hours"}
              placeholder="Enter Hours"
              type="number"
              regex={/[4-9]|[1-9]\d+/}
              errorMessage="Minimum 4 Hours Allowed"
            />
          </div>
        )}
        <div className="input-container">
          <p>Luggage (in Kg's)</p>
          <CustomInput
            onChange={handleChange}
            value={personalData["luggage"]}
            name={"luggage"}
            placeholder="Enter Luggage"
            type="number"
            // regex={/[4-9]/}
            // errorMessage="Minimum 4 Hours Allowed"
          />
        </div>
      </div>
    </div>
  );
};

export default Step1;
