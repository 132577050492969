import { useEffect } from "react";
import { PaymentElement } from "@stripe/react-stripe-js";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import popUpAlert from "../alert";
import { ContactUs } from "../emailSend";
import { PRICINGTYPE_OPTIONS } from "../../../constants";

function Test(props) {
  const stripe = useStripe();
  const elements = useElements();
  const { data, clicked } = props;

  const handleSubmit = async () => {
    if (props.clicked > 0) {
      if (!stripe || !elements) {
        return;
      }

      const { error } = await stripe.confirmPayment({
        elements,
        redirect: "if_required",
      });

      if (error?.type === "card_error" || error?.type === "validation_error") {
        popUpAlert("error", error.message, "Payment Declined");
      } else {
        popUpAlert(
          "success",
          "Your booking has been confirmed you will receive a confirmation mail soon",
          "Payment success"
        );
        let emailParam = {
          email: data.email.value,
          mobile: data.mobileNumber.value,
          pricingType: PRICINGTYPE_OPTIONS?.filter(i => i.value === data["pricingType"]?.value)[0]?.label,
          date: data.date,
          startLocation: data.startLocation.value.name,
          endLocation: data.endLocation.value.name,
          price: data.cost,
          car: data["selectedCar"].value.label || "",
          name: data.name.value,
          subject: "",
          paymentMethod: "Card",
          babySeats: data.babySeat.value,
          bcc: "hr.chauffeurservice@gmail.com",
          luggage: data["luggage"].value,
        };
        if (data["tripOption"] == "hourly") {
          emailParam.hours = data?.["hours"]?.value;
        }
        ContactUs(emailParam, "client_template");
        ContactUs(emailParam, "our_template");
        props.close();
      }
    }
  };

  useEffect(() => {
    handleSubmit();
  }, [clicked]);

  return (
    <>
      <div className="step-2-div">
      </div>
      <PaymentElement id="payment-element" />
    </>
  );
}

export default Test;
