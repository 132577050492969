import React, { Component } from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
// you will need the css that comes with bootstrap@3. if you are using
// a tool like webpack, you can do the following:
// import 'bootstrap/dist/css/bootstrap.css';
// you will also need the css that comes with bootstrap-daterangepicker
import "bootstrap-daterangepicker/daterangepicker.css";
import "./style.scss";
import calendarIcon from "../../../assets/images/icons/calendarIcon.svg";
import moment from "moment";

class DateRange extends Component {
  handleEvent = (e, picker) => {
    this.props.setDate(picker.startDate._d);
  };

  render() {
    const { date, minDate = new Date(), format="MM/DD/YYYY HH:mm" } = this.props;
    return (
      <DateRangePicker
        initialSettings={{
          startDate: date,
          //   endDate: "3/1/2014 23:33:22",
          singleDatePicker: true,
          timePicker: true,
          locale: { format: "MM/DD/YYYY HH:mm" },
          minDate: minDate,
          alwaysShowCalendars: true,
          opens: "left",
        }}
        onApply={this.handleEvent}
      >
        <button className="dateRange_pickerBtn">
          <div className="dateText" aria-hidden="true">
            <div className="">{moment(date).format(format)}</div>
          </div>
          <label className="addOn">
            <img src={calendarIcon} alt="Calendar Icon" />
          </label>
        </button>
      </DateRangePicker>
    );
  }
}

export default DateRange;
