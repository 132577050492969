
import React, { useEffect, useState } from 'react';
import { db } from '../../../firebase';
import Header from '../../common/componentHeader';
import './style.scss';
import ReactPaginate from 'react-js-pagination';
import { Button } from 'rsuite';


function Quote() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 25;


  const token = "zdfgegdbf-fhbdbdbsvvbsvv"

  useEffect(() => {
    const fetchData = async () => {
      try {
        const snapshot = await db.collection("Chauffeur44").get();
        const data = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
      
        setData(data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data from Firestore:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, []); // Empty dependency array to run the effect once



  // Replace with your authentication logic
  const handleLogin = (e) => {
    e.preventDefault();
    if (username === 'admin@hrchauffeur.com' && password === '123admin') {
      // Set the logged-in state to true if credentials are correct
      localStorage.setItem('token', token);
      setIsLoggedIn(true);
      setTimeout(() => {
        handleLogout();
      }, 1800000);
    }else{
        alert("Invalid Credentials");
    }
  };

  const handleLogout = () => {
  
    localStorage.removeItem('token');
    setIsLoggedIn(false);
    window.location.reload();
  };


  function handleDownload() {
    const header = 'Name,Email,Phone,"Pickup Date","Start Location","End Location","Date"\n';
    // Create a CSV string from your data
    const csvData = data.slice() // Create a copy of the data array
    .filter(item => item.currentDateTime && item.isVisible === 1) 
    .sort((a, b) => {
      // Sort the data in descending order based on the date field
      return b.currentDateTime.localeCompare(a.currentDateTime);
    }).map((item) => ({
    //   Index: item.index,
     
      Name: item.name,
      // Email: item.email,
      Phone: item.mobile,
      // 'Pickup Date': item.date,
      'Start Location': `"${item.startLocation.name}"`, // Wrap in double quotes
      'End Location': `"${item.endLocation.name}"`, 
      
      Date: item.currentDateTime,
    }));

    // Convert the data to CSV format
    const csvString = header + csvData.map((item) => Object.values(item).join(',')).join('\n');

    // Create a Blob from the CSV string
    const blob = new Blob([csvString], { type: 'text/csv' });

    // Create a URL for the Blob
    const url = URL.createObjectURL(blob);

    // Create an anchor element for downloading
    const a = document.createElement('a');
    a.href = url;
    a.download = 'data.csv';

    // Trigger the download
    document.body.appendChild(a);
    a.click();

    // Clean up
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  }



  const token1 = localStorage.getItem('token');


  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedData = data.slice(startIndex, endIndex);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };
 
  function handleVisibility(userId) {
    // Assuming 'Chauffeur88' is the Firestore collection name
    const userRef = db.collection("Chauffeur44").doc(userId);
  
    userRef.get().then((doc) => {
      if (doc.exists) {
        const isVisible = doc.data().isVisible || 0;
        // Toggle isVisible between 0 and 1
        const updatedVisibility = isVisible === 0 ? 1 : 0;
  
        // Update Firestore document
        userRef.update({
          isVisible: updatedVisibility,
        });
        // window.location.reload();
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    });
  }



  return (
    <div className="h_100 w_100 aboutus_section transitionIn-animation pb_20" id="aboutus">

      
      
      <div style={{ display: 'block', textAlign: 'center', marginTop:'55px' }}>
        {token1 ? (  <div>
          <h2 style={{padding:'25px', color:'white'}}>Quotation Form </h2>
          
      {loading ? (
        <p>Loading data...</p>
      ) : (
        <table style={{ width: '100%', marginBottom:'40px' }}>
          <thead >
            <tr  style={{ backgroundColor: '#fa9736', height:'45px', fontSize:'18px' }}>
              <th style={{ paddingTop: '10px' }}>Index</th>
              {/* <th>Pickup Date</th> */}
              {/* <th>id</th> */}
              <th>Name</th>
              {/* <th>Email</th> */}
              <th>Phone</th>
              <th>Start Location</th>
              <th>End Location</th>
              <th>Date</th>
              <th>Button</th>
            </tr>
          </thead>
          <tbody>
          {paginatedData
            .slice() // Create a copy of the data array
            // .filter(item => item.currentDateTime ) 
            .filter(item => item.currentDateTime  && item.isVisible === 1) 
            .sort((a, b) => {
              // Sort the data in descending order based on the date field
              return b.currentDateTime.localeCompare(a.currentDateTime);
            })
            .map((item, index) => (
              <tr
                key={item.id}
                style={{
                  backgroundColor: index % 2 === 0 ? '#f2f2f2' : 'white',
                  height: '30px', fontSize:'14px',
                }}
              >
                <td style={{ padding: '10px' }}>{index + 1}</td>
                {/* <td style={{ paddingTop: '15px' }}>{item.id}</td> */}
                {/* <td style={{ padding: '10px' }}>{item.date}</td> */}
                <td style={{ padding: '10px' }}>{item.name}</td>
                {/* <td style={{ padding: '10px' }}>{item.email}</td> */}
                <td style={{ padding: '10px' }}>{item.phone}</td>
                <td style={{ padding: '10px' }}>{item.startLocation.name}</td>
                <td style={{ padding: '10px' }}>{item.endLocation.name}</td>
                <td style={{ padding: '10px' }}>{item.currentDateTime}</td>
                <td style={{ padding: '10px' }}><button className="nxt-btn mr-15"
              onClick={() => handleVisibility(item.id)}
            >
              Delete
            </button></td>
              </tr>
            ))}
        </tbody>

        
        {/* <tbody>
                {paginatedData.map((item, index) => (
                  <tr
                    key={item.id}
                    style={{
                      backgroundColor: index % 2 === 0 ? '#f2f2f2' : 'white',
                      height: '30px',
                    }}
                  >
                    <td style={{ paddingTop: '10px' }}>{index + 1}</td>
                    <td style={{ paddingTop: '10px' }}>{item.date}</td>
                    <td style={{ paddingTop: '10px' }}>{item.name}</td>
                    <td style={{ paddingTop: '10px' }}>{item.email}</td>
                    <td style={{ paddingTop: '10px' }}>{item.mobile}</td>
                    <td style={{ paddingTop: '10px' }}>{item.startLocation}</td>
                    <td style={{ paddingTop: '10px' }}>{item.endLocation}</td>
                    <td style={{ paddingTop: '10px' }}>{item.currentDateTime}</td>
                  </tr>
                ))}
              </tbody> */}
             
      </table>
        
      )}
      <button
              className="nxt-btn mr-15"
              onClick={handleDownload}
            >
              Download
            </button>
      <button
              className="nxt-btn mr-15"
              onClick={handleLogout}
            >
              Log Out
            </button>
            <ReactPaginate
              activePage={currentPage}
              itemsCountPerPage={itemsPerPage}
              totalItemsCount={data.length}
              pageRangeDisplayed={5}
              onChange={handlePageChange}
            />
    </div>
        ) : (
           
          <div style={{textAlign:'start'}}>
             <div className="head-background">
        <Header secondaryHeading={'Admin'} subHeading={''} />
      </div>
            <p className="aboutus-description" style={{ fontSize: '25px', textAlign:'center' }}>
              Login Here!
            </p>
            {/* Login form */}
            <form className="login-form" onSubmit={handleLogin}>
              <input
                type="text"
                placeholder="Username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
              <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <button type='submin' className='nxt-btn' >Log In</button>
            </form>
          </div>
        )}
      </div>
    </div>
  );
}

export default Quote;
