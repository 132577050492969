import "./style.scss";

const Card2 = (props) => {

    const { data:{imgSrc, heading, content} } = props;
    return (
        <div className="card-wrapper card_services card_services-2">
            <img src={imgSrc} alt={heading} />
            <h3 className="heading">{heading}</h3>
            {/* <p className="content">{content}</p> */}
            {/* <button className="outline_btn hire_btn hire_btn_card">Know More</button> */}
        </div>
    )
}

export default Card2