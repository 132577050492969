import React from "react";
import { ABOUT, ABOUT_US_DESCRIPTION, US } from "../../../constants";
import Cards from "../../common/cards";
import Header from "../../common/componentHeader";
import "./style.scss";
import { Helmet } from 'react-helmet';

const AboutUs = () => {
  return (
    <div
      className="h_100 w_100 aboutus_section transitionIn-animation pb_20"
      id="aboutus"
    >
       <Helmet>
        <title>Car Chauffeur Service in Perth - HR Chauffeur</title>
        <meta name="description" content="Discover the best private car rental and chauffeur service in Perth. Choose HR Chauffeur car rental company and enjoy premium chauffeur-driven experiences." />
        <link rel="canonical" href="https://www.hr-chauffeur.com.au/aboutus" />
     </Helmet>
      <div className="head-background">
      <Header secondaryHeading={ABOUT} subHeading={US} />
      </div>
      <div className="w_80">
        <p className="aboutus-description">{ABOUT_US_DESCRIPTION}</p>
      </div>
    </div>
  );
};

export default AboutUs;
