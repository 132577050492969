import moment from "moment";
import { TRIPOPTION_OBJECT } from "../constants";

export const test = () => {};

export const checkIfAirport = (googleObj) => {
  let isAirport = false;
  !!googleObj &&
    googleObj.map((item) => {
      if (item.types.includes("airport")) {
        isAirport = true;
      }
    });
  return isAirport;
};

export const renderBtnText = (step) => {
  switch (step) {
    case 1:
      return "Next";
    case 2:
      return "Move to payment";
    case 3:
      return "Make Payment";
    default:
  }
};

/**
 * Checks for errors in the provided data based on the current step and address information.
 * @param {object} data - The data to be checked for errors.
 * @param {number} step - The current step of the process.
 * @param {object} startAddress - The start address object containing latitude and longitude.
 * @param {object} endAddress - The end address object containing latitude and longitude.
 * @returns {object} - An object containing the updated data and error flag.
 */
export const checkIfError = (data, step, startAddress, endAddress) => {
  let updatedData = { ...data }; // Create a shallow copy of the data object
  let error = false;
  let obj = {};

  if (step === 1) {
    // Step 1: Check start and end locations
    updatedData["endLocation"].focused = true;
    updatedData["startLocation"].focused = true;

    if (startAddress.lat === 0 && startAddress.lng === 0) {
      error = true;
      updatedData["startLocation"].error = true;
    } else {
      updatedData["startLocation"].error = false;
    }

    if (endAddress.lat === 0 && endAddress.lng === 0) {
      error = true;
      updatedData["endLocation"].error = true;
    } else {
      updatedData["endLocation"].error = false;
    }

    if (updatedData["tripOption"] === "hourly" && updatedData["hours"]?.value < 4) {
      error = true;
    }
  } else if (step === 2) {
    // Step 2: Check selected car
    if (Object.keys(updatedData["selectedCar"]?.value).length === 0) {
      error = true;
      updatedData["selectedCar"].error = true;
    } else {
      updatedData["selectedCar"].error = false;
    }
  } else if (step === 3) {
    // Step 3: Check personal data
    updatedData["name"].focused = true;
    updatedData["mobileNumber"].focused = true;
    updatedData["email"].focused = true;

    if (
      updatedData["name"].error ||
      updatedData["mobileNumber"].error ||
      updatedData["email"].error
    ) {
      error = true;
    }
  }

  obj.personalData = updatedData;
  obj.error = error;

  return obj;
};

export const returnCalculatedPrice = (personalData, distance, carType = {}) => {
  let cost = 0;
  let pricingType =
    personalData["pricingType"]?.value || personalData["transferType"];
  let selectedCar = personalData["selectedCar"]?.value || carType;
  let selectedHours = personalData["hours"]?.value || personalData["hours"];
  switch (pricingType) {
    case "weddingTransfer":
      if (personalData["tripOption"] === "hourly") {
        cost = selectedCar?.hourlyRate * selectedHours;
      } else if (personalData["tripOption"] === "oneWay") {
        if (distance > 20) {
          cost = (selectedCar?.airportRates + (distance - 20) * 3) * 1.5;
        } else {
          cost = selectedCar?.airportRates * 1.5;
        }
      } else if (personalData["tripOption"] === "return") {
        if (distance > 20) {
          cost = (selectedCar?.airportRates + (distance - 20) * 3) * 1.5 * 2;
        } else {
          cost = selectedCar?.airportRates * 2 * 1.5;
        }
      } else {
        if (distance > 20) {
          cost = (carType?.airportRates + (distance - 20) * 3) * 1.5;
        } else {
          cost = carType?.airportRates * 1.5;
        }
      }
      return cost;

    case "airport":
      if (personalData["tripOption"] === "oneWay") {
        if (isMidnightAndBeforeSix(personalData["date"])) {
          cost = selectedCar?.airportRates * 1.2;
          if (distance > 20) {
            cost = cost + (distance - 20) * 3 * 1.2;
          }
        } else {
          cost = selectedCar?.airportRates;
          if (distance > 20) {
            cost = cost + (distance - 20) * 3;
          }
        }
      } else if (personalData["tripOption"] === "return") {
        if (isMidnightAndBeforeSix(personalData["date"])) {
          cost = selectedCar?.airportRates * 1.2 * 2;
          if (distance > 20) {
            cost = cost + (distance - 20) * 3 * 2 * 1.2;
          }
        } else {
          cost = selectedCar?.airportRates * 2;
          if (distance > 20) {
            cost = cost + (distance - 20) * 3 * 2;
          }
        }
      } else {
        if (isMidnightAndBeforeSix(personalData["date"])) {
          cost = carType?.airportRates * 1.2;
          if (distance > 20) {
            cost = cost + (distance - 20) * 3 * 1.2;
          }
        } else {
          cost = carType?.airportRates;
          if (distance > 20) {
            cost = cost + (distance - 20) * 3;
          }
        }
      }

      return cost;

    case "wineTour":
      cost = selectedCar?.hourlyRate * selectedHours;

      return cost;

    case "social":
      if (personalData["tripOption"] === "oneWay") {
        cost = selectedCar?.airportRates * 1.2;
        if (distance > 20) {
          cost = cost + (distance - 20) * 3 * 1.2;
        }
      } else if (personalData["tripOption"] === "return") {
        cost = selectedCar?.airportRates * 1.2 * 2;
        if (distance > 20) {
          cost = cost + (distance - 20) * 3 * 2 * 1.2;
        }
      }
      return cost;

    case "specialEvents":
      if (personalData["tripOption"] === "oneWay") {
        cost = selectedCar?.airportRates * 1.3;
        if (distance > 20) {
          cost = cost + (distance - 20) * 3 * 1.3;
        }
      } else if (personalData["tripOption"] === "return") {
        cost = selectedCar?.airportRates * 1.3 * 2;
        if (distance > 20) {
          cost = cost + (distance - 20) * 3 * 2 * 1.3;
        }
      }
      return cost;

    case "other":
      if (personalData["tripOption"] === "oneWay") {
        if (isMidnightAndBeforeSix(personalData["date"])) {
          cost = selectedCar?.airportRates * 1.2;
          if (distance > 20) {
            cost = cost + (distance - 20) * 3 * 1.2;
          }
        } else {
          cost = selectedCar?.airportRates;
          if (distance > 20) {
            cost = cost + (distance - 20) * 3;
          }
        }
      } else {
        if (isMidnightAndBeforeSix(personalData["date"])) {
          cost = selectedCar?.airportRates * 1.2 * 2;
          if (distance > 20) {
            cost = cost + (distance - 20) * 3 * 2 * 1.2;
          }
        } else {
          cost = selectedCar?.airportRates * 2;
          if (distance > 20) {
            cost = cost + (distance - 20) * 3 * 2;
          }
        }
      }
      return cost;

    case "asDirected":
      cost = selectedCar?.hourlyRate * selectedHours;
      return cost;

    default:
      return 0;
  }
};

export const isMidnightAndBeforeSix = (date) => {
  var time = moment(moment(date).format("h:mma"), "h:mma");
  var beginningTime = moment("12:00am", "h:mma");
  var endTime = moment("6:00am", "h:mma");

  if (beginningTime.isBefore(time) && endTime.isAfter(time)) {
    return true;
  } else {
    return false;
  }
};

//quotation form validation

export const checkIfValid = (formData, address) => {
  let error = false;
  let message = "";
  let messageHeading = "Invalid Details";
  // let emailRegex = /^\w+([.-]?\w+)@\w+([.-]?\w+)(\.\w{2,3})+$/;
  let returnObj = {};

  if (formData.name.length === 0) {
    error = true;
    message = "Please fill your name";
  } else if (
  formData.phone.length === 0) {
    error = true;
    message = "Please fill your phone number";
  } else if (
  formData.transferType.length === 0) {
    error = true;
    message = "Please Select a Transfer Type";
  } else if (
    formData["startLocation"].length === 0 ||
    (address.startAddress.lat === 0 && address.startAddress.lat === 0)
  ) {
    error = true;
    message = "Please Enter a valid start location";
    messageHeading = "Location Error";
  } else if (
    formData["endLocation"].length === 0 ||
    (address.endAddress.lat === 0 && address.endAddress.lat === 0)
  ) {
    error = true;
    message = "Please Enter a valid end location";
    messageHeading = "Location Error";
  } else if (formData.carType.length === 0) {
    error = true;
    message = "Please Select a Car";
  } else if (formData.tripOption === "hourly" && formData.hours < 4) {
    error = true;
    message = "Minimum 4 hours required";
  }

  returnObj.error = error;
  returnObj.message = message;
  returnObj.messageHeading = messageHeading;

  return returnObj;
};

export const ifAirportLocationValidation = (formData) => {
  if (formData.transferType === "airport") {
    if (
      formData.startLocation.name?.toLowerCase().includes("airport") ||
      formData.endLocation.name?.toLowerCase().includes("airport")
    ) {
      return true;
    } else {
      return false;
    }
  }

  return true;
};

//email template

export const PERSONALDATA_KEYS = {
  name: "Name",
  mobileNumber: "Mobile Number",
  email: "Email",
  hours: "Hours",
  pricingType: "Transfer Type",
  date: "Pickup Date",
  startLocation: "Start Location",
  endLocation: "End Location",
  flight: "Flight Number",
  luggage: "Luggage",
  selectedCar: "Car",
  carType: "Car",
  cost: "Cost",
  distance: "Distance",
  paymentMethod: "Payment Method",
  babySeat: "Baby Seats",
  tripOption: "Trip Option",
  transferType: "Transfer Type",
  returnDate: "Return Date",
  duration: "Duration",
  suv: "SUV",
  sedan: "Sedan",
  van: "Luxury People Mover",
  airport: "Airport Transport",
  weddingTransfer: "Wedding Transfer",
  wineTour: "Wine Tour",
  social: "Ball / Social",
  specialEvents: "Special Events / Concerts",
  other: "Other",
  asDirected: "As Directed"
};
export const ReturnTable = (data) => {
  return (
    `<div className="quotation-modal-table-wrapper">
          <table className="quotation-modal-table">
            <thead>
              <tr>
                <th colspan={2}>Your Journey Details</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="first-child">Start Location</td>
                <td className="second-child">${data?.startLocation?.name}</td>
              </tr>
              <tr>
                <td className="first-child">End Location</td>
                <td className="second-child">${data?.endLocation?.name}</td>
              </tr>
              <tr>
                <td className="first-child">Date & Time</td>
                <td className="second-child">${data?.date}</td>
              </tr>
              <tr>
                <td className="first-child">Transfer Type</td>
                <td className="second-child">
                  ${PERSONALDATA_KEYS[data?.transferType]}
                </td>
              </tr>
              <tr>
                <td className="first-child">Car</td>
                <td className="second-child">
                  ${PERSONALDATA_KEYS[data?.carType]}
                </td>
              </tr>
              <tr>
                <td className="first-child">Trip Option</td>
                <td className="second-child">
                  ${TRIPOPTION_OBJECT[data?.tripOption]}
                </td>
              </tr>
              ${data?.tripOption === "hourly" && (
                `<tr>
                  <td className="first-child">Hours</td>
                  <td className="second-child">${data?.hours} Hours</td>
                </tr>`
              )}
              <tr>
                <td className="first-child">Duration</td>
                <td className="second-child">${data?.duration}</td>
              </tr>
              <tr>
                <td className="first-child">Distance</td>
                <td className="second-child">
                  ${data?.distance?.toFixed(0)} KM
                </td>
              </tr>
              <tr>
                <td className="first-child">Cost</td>
                <td className="second-child">AUD ${data?.cost}</td>
              </tr>
            </tbody>
          </table>
        </div>`
  )
}

//distance calculation

export async function calculateDistance(personalData) {
  let google = window.google;
  let directionsService = new google.maps.DirectionsService();
  let startLocation =
    personalData?.startLocation.value || personalData?.startLocation;
  let endLocation =
    personalData?.endLocation.value || personalData?.endLocation;
  let obj = {};
  // var distance = 0;
  // let mk1 = new google.maps.Marker({ position: startLocation });
  // let mk2 = new google.maps.Marker({ position: endLocation });
  const route = {
    origin: startLocation,
    destination: endLocation,
    travelMode: "DRIVING",
  };

  await directionsService.route(route, function (response, status) {
    if (status !== "OK") {
      window.alert("Directions request failed due to " + status);
      return;
    } else {
      var directionsData = response.routes[0].legs[0]; // Get data about the mapped route
      if (!directionsData) {
        window.alert("Directions request failed");
        return;
      } else {
        obj.wayPoints = response.geocoded_waypoints;
        obj.distance = directionsData.distance.value / 1000;
        obj.duration = directionsData.duration.text;
      }
    }
  });
  return obj;
}
