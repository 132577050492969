import car from "../assets/images/car.png";
import carAudi from "../assets/images/car-audi.png";
import carBMW from "../assets/images/BMW-7.png"
import S_class from "../assets/images/S-class.png"
import BMW_5 from "../assets/images/BMW-5.png"
import audiA6 from "../assets/images/audiA6.png"
import carMerc_v from "../assets/images/merc-v.png";
import carMerc_e from "../assets/images/merc.png";
import car1 from "../assets/images/cars/car1.png"
import car3 from "../assets/images/cars/car3.png"
import car4 from "../assets/images/cars/car4.png"
import car6 from "../assets/images/cars/car6.png"
import car12 from "../assets/images/cars/car12.png"
import { ReactComponent as CreditCardIcon } from "../assets/images/icons/credit-cards.svg";
import { ReactComponent as CarKeyIcon } from "../assets/images/icons/car-key.svg";
import { ReactComponent as SteeringWheel } from "../assets/images/icons/steering-wheel.svg";
import { ReactComponent as CarReturn } from "../assets/images/icons/car-return.svg";
import { ReactComponent as FuelPump } from "../assets/images/icons/fuel-pump.svg";
import { ReactComponent as NoHiddenCharges } from "../assets/images/icons/no-hidden-charges.svg";
import { ReactComponent as GiftBox } from "../assets/images/icons/gift-box.svg";
import { ReactComponent as Gps } from "../assets/images/icons/gps.svg";
import { ReactComponent as RoadSide } from "../assets/images/icons/roadside-assistance.svg";
import { ReactComponent as Insuarance } from "../assets/images/icons/insurance.svg";
import { ReactComponent as CashIcon } from "../assets/images/icons/cash.svg";
import airport from "../assets/images/airport.jpg";
import city from "../assets/images/city.jpg";
import luxury from "../assets/images/luxury.jpg";
import hourly from "../assets/images/hourly.jpg";
import p2p from "../assets/images/p2p.jpg";
import corporate from "../assets/images/corporate.jpg";

export const TEXT = "TEST_TEXT";
export const BOOK_TEXT = "BOOK A";
// export const A_TEXT="A";
export const HIRE_TEXT = "HIRE A";
export const LUXURY_TEXT = "LUXURY";
export const CAR_TEXT = "CAR";
export const CLICK_TEXT = "IN A CLICK";
export const HOURLY_RATE = "$90/HOUR";
export const MINIMUM = "MINIMUM";
export const HIRE = "HIRE NOW";
export const ADVANTAGE = "ADVANTAGES";
export const WHAT_ARE_THE = "WHAT ARE THE";

export const ABOUT_US_DESCRIPTION = `Welcome to our chauffeur service website! We are a premier provider of luxury transportation services, serving clients throughout perth, Australia.
Our team of experienced and professional chauffeurs is dedicated to providing you with a safe, comfortable, and reliable travel experience. We pride ourselves on our attention to detail and our commitment to excellence, ensuring that every aspect of your journey is seamless and stress-free.
Whether you need transportation for business meetings, airport transfers, special events, or just a night out on the town, we have the perfect vehicle and driver to meet your needs. Our fleet of luxury cars, SUVs, and limousines is meticulously maintained and equipped with all the latest amenities, ensuring that you travel in style and comfort.
At our chauffeur service, we believe that every client is unique and deserves personalized attention. That's why we offer customized transportation solutions tailored to your specific requirements. Whether you need a single ride or ongoing service, we are here to exceed your expectations and provide you with the highest level of service.
Thank you for considering our chauffeur service for your transportation needs. We look forward to serving you and helping you create unforgettable memories.`;

export const ABOUT_US_DESCRIPTION_1 = `Welcome to HR Chauffeur Service, the premier chauffeur service for those seeking luxury, comfort, and safety. Our mission is to provide an exceptional transportation experience for our clients, whether it's for business or pleasure.We offer a wide range of services, including airport transfers, corporate transportation, special events, and city tours. Our fleet of vehicles features the latest models, including sedans, SUVs, and vans, all maintained to the highest standards to ensure your comfort and safety.
Our team of professional chauffeurs are highly trained and experienced, ensuring that you arrive at your destination on time, every time. They are courteous, friendly, and knowledgeable about the local area, making them the perfect choice for those seeking a stress-free travel experience.`;
export const ABOUT_US_DESCRIPTION_2 = `In addition to our exceptional service, we also offer competitive pricing to ensure that our services are accessible to everyone. We believe that luxury transportation shouldn't break the bank, which is why we offer affordable rates without compromising on quality. At HR Chauffeur Service, we pride ourselves on providing an exceptional transportation experience. So why settle for anything less? Book our services today and experience the difference for yourself.`;

export const SERVICES_OFFERED_1 = `Welcome to HR Chauffeur service, your premium chauffeur service provider. Our goal is to provide you with a hassle-free and enjoyable transportation experience, whether you're traveling for business or leisure.
At Chauffeur service, we offer a range of services to meet your needs, including airport transfers, corporate transportation, weddings, special events, and more. Our services are available 24/7, so you can count on us for your transportation needs anytime, anywhere.`;

export const SERVICES_OFFERED_2 = `Our fleet of luxury vehicles is carefully selected to provide the highest level of comfort and safety. From sedans to SUVs and limousines, our vehicles are equipped with the latest technology and amenities to ensure a comfortable ride. Our chauffeurs are licensed and highly trained, with years of experience in the industry. They are committed to providing the highest level of service and ensuring that you arrive at your destination safely and on time.`;
export const SERVICES_OFFERED_3 = `We understand that every client has unique needs, which is why we offer personalized services tailored to your requirements. Whether you need a ride to the airport or a city tour, we can create a package that suits your needs and budget.
At HR Chauffeur service, we are committed to providing you with the best possible transportation experience. So why wait? Book your ride with us today and experience the luxury and comfort of HR Chauffeur service.`;

export const ADVANTAGE_CARD_LIST = [
  {
    imgSrc: <FuelPump />,
    heading: "FUEL COST INCLUDED",
    content:
      "Dont't worry about mileage! All fuel costs are included. If you refill fuel, we'll pay you back!",
  },
  {
    imgSrc: <NoHiddenCharges />,
    heading: "NO HIDDEN CHARGES",
    content:
      "Our prices include taxes and insurance. What you see is what you really pay!",
  },
  {
    imgSrc: <GiftBox />,
    heading: "FLEXI PRICING PACKAGES",
    content:
      "One size never fits all! Choose a balance of time and kilometers that works best for you.",
  },
  {
    imgSrc: <Gps />,
    heading: "GO ANYWHERE",
    content:
      "Our cars have all-Australia permits. Just remember to pay state tolls and entry taxes.",
  },
  {
    imgSrc: <RoadSide />,
    heading: "24X7 ROADSIDE ASSISTANCE",
    content:
      "We have round-the-clock, West Australia partners. Help is never far away for you.",
  },
  {
    imgSrc: <Insuarance />,
    heading: "DAMAGE INSURANCE",
    content:
      "All your bookings include damage insurance! Drive safe, but don't worry!",
  },
];

export const SERVICES_CARD_LIST = [
  {
    imgSrc: airport,
    alt: "airport transfer chauffeur",
    heading: "Airport Transfers",
    content:
      "Chauffeur companies often provide airport transfer services, which involve picking up clients from the airport and transporting them to their destination. In luxury vehicles",
    link: "/airporttransport",
  },
  {
    imgSrc: p2p,
    alt: "Point to Point transfer chauffer service",
    heading: "Point-to-Point Transportation",
    content:
      "Companies may provide point-to-point transportation, where clients are transported from one point to another, such as from their hotel to a restaurant or shopping destination.",
    link: "/p2ptransport",
  },
  {
    imgSrc: city,
    alt: "city tours chauffeurs",
    heading: "City Tours",
    content:
      "Some companies may offer city tours, which involve providing transportation for clients to see the sights and attractions of a particular city.",
    link: "/citytransport",
  },

  {
    imgSrc: luxury,
    alt: "Limousine chauffeur service",
    heading: "Luxury Transportation",
    content:
      "Some companies may offer city tours, which involve providing transportation for clients to see the sights and attractions of a particular city.",
    link: "/luxurytransport",
  },
  {
    imgSrc: hourly,
    alt: "Hourly Chauufeur Service",
    heading: "Hourly Services",
    content:
      "Chauffeur companies may offer hourly services, where clients can hire a chauffeur and vehicle for a specified period of time. We peovide car rental hourly in perth",
    link: "/hourlytransport",
  },
  {
    imgSrc: corporate,
    alt: "Corporate meeting transfer chauffeur perth",
    heading: "Corporate Transportation",
    content:
      "We offer corporate transportation services, which involve providing transportation for corporate clients to and from meetings, events, or other business-related activities.",
    link: "/corporatetransport",
  },
  // {
  //   imgSrc: corporate,
  //   alt: "Wine Tour Chauffeur perth",
  //   heading: "Wine Tour Chauffeur ",
  //   content:
  //     "Indulge in the ultimate wine-tasting experience with our Wine Tour Chauffeur in Perth! Sit back, relax, and savor the finest wines of the region as our expert chauffeur takes you on a luxurious journey through picturesque vineyards. Book your exclusive wine tour with HR Chauffeur and elevate your tasting experience! ",
  //   // link: "/#",
  // },
  // {
  //   imgSrc: corporate,
  //   alt: "Wedding Chauffeur Car perth",
  //   heading: "Wedding Chauffeur Car Service",
  //   content:
  //     "Step into a world of refined luxury with HR Chauffeur, your ultimate choice for wedding transportation in Perth. Whether you're preparing for the most important day of your life or celebrating love, our chauffeured car services are tailored to make your wedding day an unforgettable experience.",
  //   // link: "/#",
  // },
];

export const Details = [
  {
    label: "Daily Rides",
    data: "3,00+",
  },
  {
    label: "Happy Users",
    data: "5,00+",
  },
  {
    label: "Km Travelled",
    data: "3M+",
  },
  {
    label: "Number of Cars",
    data: "15+",
  },
];

//SERVICES DAHBOARDS HEADINGS :
export const SERVICES = "TYPE OF CARS";
export const WE_OFFERED = "WE OFFER";

//SERVICES DASHBOARDS CARDS SECTION :
export const CARSDETAILS = [
  {
    service: "EXECUTIVE SEDAN",
    ride_details: "SEDAN RIDES",
    amount: "$90/Hour",
    url: carMerc_e,
    carType: "(Upto 3 Passenger)",
    car: "> Mercedese E class",
    car1: "> BMW 5 Series",
    car2: "> Audi A6",
    alt: "Mercedes Benz black Car and chauffeur",
  },
  {
    service: "PREMIUM SEDAN",
    ride_details: "SUV RIDES",
    amount: "$110/Hour",
    url: carBMW,
    carType: "(Upto 3 Passenger)",

    car: "> Mercedese S series",
    car1: "> BMW 7 Series",
    car2: "> Audi A8",
    alt: "SUV Audi Chauffeur Service Perth",
  },
  {
    service: "LUXURY MOVER",
    ride_details: "VAN RIDES",
    amount: "$125/Hour",
    url: carMerc_v,
    carType: "(Upto 7 Passenger)",
    car: "> Mercedes V class",
    // car1: "> Mercedes Vito",
    // car2: "> Audi Q7",

    alt: "Mercedese Chauffeur Van Rides",
  },
  // {
  //   service: "CARS",
  //   ride_details: "SEDAN RIDES",
  //   amount: "$90/Hour",
  //   url: carMerc_e,
  //   carType: "sedan",
  //   car: "Mercedese",
  //   alt:"Mercedes Benz black Car and chauffeur",
  // },
  // {
  //   service: "CARS",
  //   ride_details: "SUV RIDES",
  //   amount: "$100/Hour",
  //   url: carAudi,
  //   carType: "suv",
  //   car: "AudiSuv",
  //   alt:"SUV Audi Chauffeur Service Perth",
  // },
  // {
  //   service: "CARS",
  //   ride_details: "VAN RIDES",
  //   amount: "$125/Hour",
  //   url: carMerc_v,
  //   carType: "van",
  //   car: "Mercedese",
  //   alt:"Mercedese Chauffeur Van Rides",
  // },

];

//HOW IT WORKS DASHBOARD HEADING

export const LEARN = "LEARN";
export const HOW_IT = "HOW IT";
export const ABOUT = "ABOUT";
export const US = "US";
export const WORKS = "WORKS";

export const CARDS_DATA = [
  {
    image: <CreditCardIcon />,
    name: "BOOK & PAY",
    details: "Pick your favourite car, time and place",
  },
  {
    image: <CarKeyIcon />,
    name: "RECEIVE",
    details: "We will deliver the car to your doorstep",
  },
  {
    image: <SteeringWheel />,
    name: "DRIVE",
    details: "Enjoy your drive",
  },
  {
    image: <CarReturn />,
    name: "RETURN",
    details: "Get the car back to your preferred return location.",
  },
];

export const SelectCarDropDown = [

  

  {
    value: "Executive Sedan",
    image: audiA6,
    label: "EXECUTIVE SEDAN",
    type: "sedan",
    hourlyRate: 90,
    airportRates: 90,
    distanceRates: 10,
  },

  {
      value: "Premium Sedan",
      image: carBMW,
      label: "PREMIUM SEDAN",
      type: "suv",
      hourlyRate: 110,
      airportRates: 110,
      distanceRates: 11,
    },

    {
      value: "Luxury Mover",
      image: carMerc_v,
      label: "LUXURY MOVER",
      type: "van",
      hourlyRate: 125,
      airportRates: 125,
      distanceRates: 12,
    },



  // {
  //   value: "mercedesV",
  //   image: carMerc_v,
  //   label: "Mercedes V-Class",
  //   type: "van",
  //   hourlyRate: 125,
  //   airportRates: 125,
  //   distanceRates: 12,
  // },
  // {
  //   value: "mercedesE",
  //   image: carMerc_e,
  //   label: "Mercedese E-class",
  //   type: "sedan",
  //   hourlyRate: 90,
  //   airportRates: 90,
  //   distanceRates: 10,
  // },
  // {
  //   value: "audiSuv",
  //   image: carAudi,
  //   label: "Audi A8",
  //   type: "suv",
  //   hourlyRate: 110,
  //   airportRates: 110,
  //   distanceRates: 11,
  // },
  // // {
  // //   value: "rangeRoverSuv",
  // //   image: car,
  // //   label: "Range Rover",
  // //   type: "suv",
  // //   hourlyRate: 110,
  // //   airportRates: 110,
  // //   distanceRates: 10,
  // // },
  // {
  //   value: "BMW5series",
  //   image: BMW_5,
  //   label: "BMW 5 Series",
  //   type: "sedan",
  //   hourlyRate: 90,
  //   airportRates: 90,
  //   distanceRates: 10,
  // },
  // {
  //   value: "AudiA6",
  //   image: audiA6,
  //   label: "Audi A6",
  //   type: "sedan",
  //   hourlyRate: 90,
  //   airportRates: 90,
  //   distanceRates: 10,
  // },
  // {
  //   value: "BMW7Series",
  //   image: carBMW,
  //   label: "BMW 7 Series",
  //   type: "suv",
  //   hourlyRate: 110,
  //   airportRates: 110,
  //   distanceRates: 11,
  // },
  // {
  //   value: "MercedesS",
  //   image: S_class,
  //   label: "Mercedes S-class",
  //   type: "suv",
  //   hourlyRate: 110,
  //   airportRates: 110,
  //   distanceRates: 11,
  // },

];

export const carType = [
  {
    value: "suv",
    label: "EXECUTIVE SEDAN",
  },
  {
    value: "sedan",
    label: "PREMIUM SEDAN",
  },
  {
    value: "van",
    label: "LUXURY MOVER",
  },

];

export const personalDataInputs = [
  {
    name: "name",
    placeholder: "Enter Your Name",
    width: `100%`,
    type: "text",
    // svg: <AvatarIcon />,
    label: "Name",
    regex: /^[A-Za-z\s]{1,20}$/,
  },
  {
    name: "mobileNumber",
    placeholder: "Enter Your Mobile Number",
    width: `100%`,
    type: "number",
    // svg: <MobileSvg />,
    label: "Mobile Number",
    regex: /^[0-9]{1,10}$/,
  },
  {
    name: "email",
    placeholder: "Enter Your Email",
    width: `100%`,
    type: "email",
    label: "Email ID",
    regex: /^\w+([.-]?\w+)@\w+([.-]?\w+)(\.\w{2,3})+$/,
    // svg: <EmailSvg />,
  },
  // {
  //     name: "startLocation",
  //     placeholder: "Enter Your Start Location",
  //     width: `100%`,
  //     type: "search",
  //     svg: <LocationArrowSvg />,
  // },
  // {
  //     name: "endLocation",
  //     placeholder: "Enter Your End Location",
  //     width: `100%`,
  //     type: "search",
  //     svg: <LocationArrowSvg />,
  // },
];

export const SelectPricing = [
  {
    value: "airport",
    label: "Airport Transport",
  },
  {
    value: "hourly",
    label: "Hourly",
  },
];

export const api_path =
  "https://us-central1-chuffer-api-s.cloudfunctions.net/api";

export const FORM_HEADER = [
  { step: 1, value: "Journey Details" },
  { step: 2, value: "Quotation" },
  // { step: 3, value: "Personal Details" },
  { step: 3, value: "Payment / Confirmation" },
  // { step: 5, value: "Create CUR" },
];

export const JOURNEY_OBJECT_FIRST = [
  {
    key: "From",
    value: "startLocation",
  },
  {
    key: "To",
    value: "endLocation",
  },
  // { key: "Car Type", value: "selectedCar" },
  
];

export const JOURNEY_OBJECT_SECOND = [
  {
    key: "Date & Time",
    value: "date",
  },
  
  { key: "Distance", value: "distance" },
  { key: "Duration", value: "duration" },
  { key: "Price", value: "cost", suffix: "AUD" },
];

export const JOURNEY_OBJECT_THIRD = [
  { key: "Luggage", value: "luggage", suffix: "Kg" },
  { key: "BabySeat", value: "babySeat" },
  
];

export const JOURNEY_OBJECT_THIRD_HOURLY = [
  { key: "Hourly Pricing", value: "hours" },
];

export const PAYMENT_OPTIONS = [
  { value: "card", label: "Card", svg: <CreditCardIcon /> },
  // { value: "cash", label: "Cash", svg: <CashIcon /> },
];

export const DRIVE = "DRIVE YOUR";
export const DREAM = "DREAM";
export const WITH = "WITH";
export const US_DRIVE = "US";

export const OUR_COMMITMENT = "FIND ";
export const QUALITY = "YOUR PERFECT";
export const NONE = "CAR WITH US";

export const SEDAN_RATE = "$90/HOUR";
export const SUV_RATE = "$110/HOUR";
export const VAN_RATE = "$125/HOUR";

export const PRICINGTYPE_OPTIONS = [
  {
    value: "airport",
    label: "Airport Transport",
  },
  {
    value: "weddingTransfer",
    label: "Wedding Transfer",
  },
  {
    value: "wineTour",
    label: "Wine Tour",
  },
  {
    value: "social",
    label: "Ball / Social",
  },
  {
    value: "specialEvents",
    label: "Special Events / Concerts",
  },
  {
    value: "other",
    label: "Other",
  },
  {
    value: "asDirected",
    label: "As Directed",
  },
];

export const TRIPOPTION_OBJECT = {
  oneWay: "One Way",
  return: "Return",
  hourly: "Hourly",
};

export const TRIPOPTION_SPECIFIC = {
  weddingTransfer: ["oneWay", "return", "hourly"],
  // airport: ["oneWay", "return", "flight"],
  airport: ["oneWay", "return"],
  wineTour: ["hourly"],
  social: ["oneWay", "return"],
  specialEvents: ["oneWay", "return"],
  other: ["oneWay", "return"],
  asDirected: ["hourly"],
};

export const QUOTATION_FORM_INPUTS = [

  {
    name: "name",
    placeholder: "Enter Your Name",
    label: "Name",
    type: "text",
    regex: /^[A-Za-z\s]{1,20}$/,
    width: "100%",
  },
  {
    name: "phone",
    placeholder: "Enter Your Phone Number",
    label: "Phone No.",
    type: "number",
    regex: /^[A-Za-z\s]{1,10}$/,
    width: "100%",
  },



  // {
  //   name: "email",
  //   placeholder: "Enter Your Email",
  //   width: `100%`,
  //   type: "email",
  //   label: "Email ID",
  //   regex: /^\w+([.-]?\w+)@\w+([.-]?\w+)(\.\w{2,3})+$/,
  // },

  {
    name: "date",
    placeholder: "Enter Your Pickup Date and Time",
    width: `100%`,
    type: "date",
    label: "Pickup Date & Time",
    // regex: /^\w+([.-]?\w+)@\w+([.-]?\w+)(\.\w{2,3})+$/,
  },

  {
    name: "transferType",
    placeholder: "Select Type of Transfer",
    width: `100%`,
    type: "dropdown",
    label: "Type of Transfer",
    data: PRICINGTYPE_OPTIONS,
    // regex: /^\w+([.-]?\w+)@\w+([.-]?\w+)(\.\w{2,3})+$/,
  },
  {
    name: "startLocation",
    placeholder: "Enter Your Pickup Location",
    width: `100%`,
    type: "location",
    address: "startAddress",
    label: "Pickup Location",
    // regex: /^\w+([.-]?\w+)@\w+([.-]?\w+)(\.\w{2,3})+$/,
  },
  {
    name: "endLocation",
    placeholder: "Enter Your Drop Location",
    width: `100%`,
    type: "location",
    address: "endAddress",
    label: "Drop Location",
    // regex: /^\w+([.-]?\w+)@\w+([.-]?\w+)(\.\w{2,3})+$/,
  },
  {
    name: "tripOption",
    placeholder: "Select Type of Transfer",
    width: `100%`,
    type: "radio",
    label: "Trip Option",
    data: TRIPOPTION_SPECIFIC.weddingTransfer,
    // regex: /^\w+([.-]?\w+)@\w+([.-]?\w+)(\.\w{2,3})+$/,
  },
  {
    name: "hours",
    placeholder: "Hours",
    width: `100%`,
    type: "number",
    label: "Hours",
    // regex: /^[0-9]{1,15}$/,
  },
  {
    name: "carType",
    placeholder: "Select Type of Ride",
    width: `100%`,
    type: "dropdown",
    label: "Type of Ride",
    data: carType,
    // regex: /^\w+([.-]?\w+)@\w+([.-]?\w+)(\.\w{2,3})+$/,
  },
];

export const AIRPORT_TRANSFER = [
  "Welcome to our luxury airport transfer in perth, where we pride ourselves on offering the most reliable and luxury airport transportation in town. Whether you're traveling for business or pleasure, our team of experienced and friendly chauffeurs will make sure that you arrive at your destination in style and on time. Our perth airport transportation services are designed to meet the needs of all types of travelers. We offer a variety of luxury vehicles to choose from, including sedans, SUVs, and vans. All of our vehicles are meticulously maintained and cleaned to ensure that you have a comfortable and stress-free ride to and from the airport.",
  <img src={car1} style={{ float: 'left', minWidth: '300px', maxWidth: '40%' }} />,
  "Booking our perth airport transfer service is quick and easy. You can make a reservation online or by phone, and we will send you a confirmation email with all the details of your ride. We offer competitive pricing and transparent billing, so you can be sure that you are getting the best value for your money. At our car chauffeur service, we are committed to providing our clients with the highest level of service and satisfaction. Whether you're traveling for business or pleasure, you can count on HR Chauffeur to provide you with a safe, comfortable, and stress-free airport transportation experience. We provide professional pick and drop service in perth. Contact us today to book your ride!",
  "We value your time and understand the significance of being on schedule for your flights. Our chauffeurs are highly trained and have extensive knowledge of the best routes and traffic conditions, enabling them to plan your journey meticulously. We monitor flight schedules and make adjustments as necessary to ensure that you arrive at the airport with ample time for check-in and security procedures.",
  <h2 class="primary_heading">Perth Airport Transfer Services !</h2>,
  "When you choose our perth airport transfers service, you can expect nothing less than the utmost comfort and luxury. Our fleet comprises a range of meticulously maintained vehicles, including luxury sedans, SUVs, and spacious vans, all equipped with plush interiors and modern amenities. Sit back, relax, and enjoy a smooth and comfortable ride to or from the airport.",
  "Our chauffeurs are the heart and soul of our service. They are handpicked for their professionalism, extensive experience, and impeccable driving skills. They undergo rigorous background checks and are committed to providing you with the highest level of service. Our chauffeurs are not just drivers; they are knowledgeable and courteous professionals who prioritize your safety and comfort throughout your journey.",
  "At HR Chauffeur, we believe in catering to your unique needs and preferences. Whether you require assistance with luggage, have specific requests, or need to make additional stops along the way, our chauffeurs are here to accommodate you. We go above and beyond to ensure that your airport transfer is tailored to your individual requirements, making your journey as seamless and enjoyable as possible.",
];

export const CORPORATE_TRANSFER = [
  "Welcome corporate transportation services, where we specialize in providing the most reliable and professional corporate transportation in town. We understand that your time is valuable and that you need to be able to focus on your business without worrying about transportation. That's why we offer a range of corporate transportation services to meet your needs, from airport transfers to event transportation and everything in between. Our corporate transportation services are designed to meet the needs of busy executives and professionals. We offer a fleet of luxury vehicles to choose from, including sedans, SUVs, and vans, all of which are meticulously maintained and cleaned to ensure that you have a comfortable and stress-free ride. Our chauffeurs are highly trained professionals who are knowledgeable about the area and can help you navigate the city's busy streets and highways with ease.",
  <img src={car3} style={{ float: 'left', minWidth: '300px', maxWidth: '40%' }} />,
  "At our car chauffeur service, we understand the importance of professionalism and discretion in corporate transportation. Our chauffeurs are always well-dressed, courteous, and professional, and they will always respect your privacy and confidentiality. We also offer a range of amenities to help you stay productive and focused during your ride, including complimentary Wi-Fi and bottled water. We understand that schedules can change at the last minute, which is why we offer flexible booking options and 24/7 customer service. Whether you need to make a reservation for a meeting or event, or you need to make changes to your itinerary, our team of dedicated professionals is always available to assist you.",
  "At HR Chauffeur, we prioritize professionalism and discretion in all aspects of our service. Our chauffeurs are not only skilled drivers but also polished professionals who understand the importance of maintaining confidentiality and privacy. You can trust us to handle your corporate transfers with the utmost professionalism and respect.",
  "We recognize that time is a valuable asset in the corporate world. Our chauffeurs are experts in navigating the most efficient routes and staying updated on traffic conditions to ensure that you reach your destination promptly. We monitor road conditions and use advanced technology to optimize your travel time, helping you stay on schedule and make the most of your business day.",
  "Our fleet consists of top-of-the-line vehicles that are meticulously maintained to provide a luxurious and comfortable travel experience. From sleek sedans to spacious SUVs, our vehicles are equipped with plush interiors, climate control, and modern amenities to ensure that you can relax and focus on your work during the journey. We prioritize your comfort and ensure that every aspect of your transfer is designed to enhance your experience.",
  "We understand that each corporate transfer has unique requirements. Whether you need transportation for individual executives, a team of employees, or important clients, we offer customized solutions to meet your specific needs. Our dedicated team works closely with you to understand your requirements and tailor our services accordingly, ensuring a seamless and personalized experience for every passenger.",
  "For frequent corporate travelers, we offer convenient corporate account management services. With a corporate account, you gain access to streamlined booking processes, centralized billing, and detailed reporting for easy expense management. Our account managers are available to assist you with any requests, ensuring a hassle-free experience from start to finish.",
  "As a trusted chauffeur service provider, we offer nationwide coverage for your corporate transfers. Whether you require transportation in major cities or remote locations, our extensive network allows us to seamlessly arrange your transfers wherever your business takes you. You can rely on us for consistent and reliable service, no matter where your corporate travels lead.",
];

export const P2P_TRANSFER = [
  "Welcome to our car chauffeur point to point transport service, where we specialize in providing reliable and professional point to point transportation in town. Whether you need transportation for a special event, a night out on the town, or just a ride to your destination, we've got you covered. Our point to point transportation services are designed to meet your needs and your schedule. We offer a fleet of luxury vehicles to choose from, including sedans, SUVs, and vans, all of which are meticulously maintained and cleaned to ensure that you have a comfortable and stress-free ride. Our chauffeurs are highly trained professionals who are knowledgeable about the area and can help you navigate the city's busy streets and highways with ease.",
  <img src={car4} style={{ float: 'left', minWidth: '300px', maxWidth: '40%' }} />,
  "At our car chauffeur service, we understand the importance of punctuality and reliability in point to point transportation. That's why our chauffeurs are always on time, and we track your flight or trip to ensure that we are there when you need us. We also offer a range of amenities to help you stay comfortable and relaxed during your ride, including climate control, plush seating, and entertainment systems. Our point to point transportation services are also perfect for special occasions, such as weddings, proms, and anniversaries. We offer a range of luxury vehicles to choose from, including stretch limousines and party buses, to make your special occasion even more memorable.",
  "When it comes to point-to-point transfers, punctuality is key. We understand that time is valuable, and we strive to ensure that you reach your destination on time, every time. Our experienced chauffeurs are well-versed in the local area and stay updated on traffic conditions, allowing them to plan the most efficient routes and adjust as necessary. You can trust us to get you to your destination promptly and hassle-free.",
  "We take pride in our fleet of luxury vehicles that are designed to provide the ultimate comfort and style during your point-to-point transfers. Whether you prefer a sleek sedan for a solo trip or a spacious SUV for a group, our vehicles are meticulously maintained, equipped with plush interiors, and fitted with modern amenities to ensure a comfortable and enjoyable ride. Sit back, relax, and let us take care of the driving while you travel in luxury.",
  "Our chauffeurs are more than just drivers; they are professionals dedicated to providing exceptional service. They undergo rigorous training, possess extensive knowledge of the roads, and are committed to ensuring your safety and comfort throughout the journey. Our chauffeurs are courteous, well-dressed, and attentive to your needs, ensuring a pleasant and memorable experience.",
  "With our point-to-point transfer service, we offer convenient door-to-door transportation. Whether you need to be picked up from your home, office, or any other location, our chauffeur will arrive at your doorstep at the designated time, ready to transport you to your desired destination. We eliminate the hassle of parking, navigating unfamiliar areas, and dealing with traffic, allowing you to relax and enjoy a hassle-free journey.",
  "We understand that every point-to-point transfer is unique, and we offer flexible and customized solutions to meet your specific requirements. Whether you're traveling for business or leisure, we can tailor our services to accommodate your needs. From individual transfers to group transportation, we have the resources and expertise to cater to your preferences and ensure a seamless and personalized experience.",
  "We believe that luxury transportation should be accessible to everyone, and we offer competitive pricing for our point-to-point transfer services. Our rates are transparent and upfront, with no hidden fees or surprises. You can trust that you're getting the best value for your money, without compromising on the quality of our service.",
];

export const HOURLY_TRANSFER = [
  "Welcome to our car rental hourly, where we specialize in providing reliable and professional hourly transportation in town. Whether you need transportation for a business meeting, a shopping trip, or a sightseeing tour, we've got you covered. Chauffeur cost per hour are designed to meet your needs and your schedule. We offer a fleet of luxury vehicles to choose from, including sedans, SUVs, and vans, all of which are meticulously maintained and cleaned to ensure that you have a comfortable and stress-free ride. Our chauffeurs are highly trained professionals who are knowledgeable about the area and can help you navigate the city's busy streets and highways with ease.",
  <img src={car6} style={{ float: 'left', minWidth: '300px', maxWidth: '40%' }} />,
  "At our car chauffeur service, we understand the importance of flexibility in hourly transportation. That's why we offer customizable hourly packages to fit your specific needs, whether you need transportation for just a few hours or a full day. We also offer a range of amenities to help you stay comfortable and productive during your ride, including Wi-Fi, bottled water, and phone chargers. Our hourly transportation services are also perfect for events and celebrations, such as weddings, proms, and bachelor/bachelorette parties. We offer a range of luxury vehicles to choose from, including stretch limousines and party buses, to make your special occasion even more memorable. Booking our hourly transportation services is quick and easy. You can make a reservation online or by phone, and we will send you a confirmation email with all the details of your ride. We offer competitive pricing and transparent billing, so you can be sure that you are getting the best value for your money.",
  "Our hourly transfer service provides you with the ultimate flexibility and freedom to customize your itinerary. Whether you need to visit multiple locations, make stops along the way, or simply have the convenience of a dedicated chauffeur at your disposal, our service allows you to maximize your time and make the most of your day. No more worrying about parking, navigating unfamiliar areas, or waiting for transportation – our chauffeur will be there for you throughout your designated time.",
  "We take pride in our fleet of luxury vehicles that are designed to provide the utmost comfort and style during your hourly transfers. From elegant sedans to spacious SUVs, our vehicles are meticulously maintained, equipped with plush interiors, and fitted with modern amenities to ensure a luxurious and enjoyable ride. Sit back, relax, and indulge in the comfort of our vehicles while our professional chauffeur takes care of the driving.",
  "Our chauffeurs are highly trained professionals who are dedicated to providing exceptional service. They possess extensive knowledge of the roads, are skilled in navigating through traffic, and prioritize your safety and comfort. Our chauffeurs are courteous, punctual, and attentive to your needs, ensuring a smooth and pleasant experience throughout your hourly transfers.",
  "We believe in providing luxury transportation at competitive prices. Our hourly transfer service offers transparent and upfront pricing, ensuring that you receive excellent value for your money. We provide you with a detailed quote based on the duration of your transfer, eliminating any surprises or hidden fees. Experience the luxury and convenience of our service without breaking the bank.",
  "At HR Chauffeur, we prioritize customer satisfaction. From the moment you make your booking until the end of your transfer, our dedicated customer service team is available to assist you. We are here to answer any questions, accommodate any special requests, and ensure that your hourly transfers meet and exceed your expectations.",
];

export const CITY_TRANSFER = [
  "Welcome to our car chauffeur service, where we specialize in providing reliable and professional city transportation services. Whether you need transportation for a business meeting, an airport transfer, or just a ride to your destination, we've got you covered. Our city transportation services are designed to meet your needs and your schedule. We offer a fleet of luxury vehicles to choose from, including sedans, SUVs, and vans, all of which are meticulously maintained and cleaned to ensure that you have a comfortable and stress-free ride. Our chauffeurs are highly trained professionals who are knowledgeable about the area and can help you navigate the city's busy streets and highways with ease.",
  "At our car chauffeur service, we understand the importance of punctuality and reliability in city transportation. That's why our chauffeurs are always on time, and we track your flight or trip to ensure that we are there when you need us. We also offer a range of amenities to help you stay comfortable and relaxed during your ride, including climate control, plush seating, and entertainment systems. Our city transportation services are also perfect for sightseeing tours and exploring the city's attractions. We offer customizable packages that allow you to create your own itinerary, whether you want to visit museums, landmarks, or shopping destinations. Our chauffeurs are knowledgeable about the city's history and culture, and they can provide you with insider tips and recommendations.",
  <img src={car12} style={{ float: 'left', minWidth: '300px', maxWidth: '40%' }} />,
  "Our chauffeurs have extensive knowledge of the city you're visiting. They are well-versed in the local roads, traffic patterns, and popular attractions, allowing them to navigate the city efficiently and take you to your destination via the best routes. Whether you're looking for recommendations on restaurants, shopping districts, or landmarks, our chauffeurs can provide valuable insights to enhance your city exploration.",
  "Navigating a busy city can be stressful, especially if you're unfamiliar with the area. Our city transfer service offers you convenience and peace of mind. Our professional chauffeurs will handle the driving and parking, allowing you to relax and enjoy the journey. Our luxury vehicles are equipped with plush interiors, climate control, and modern amenities to ensure a comfortable and enjoyable ride while you take in the sights of the city.",
  "We understand that each city transfer is unique, and we offer personalized solutions to cater to your specific requirements. Whether you're traveling alone, with a group, or have special requests, we can accommodate your needs. Our fleet comprises a variety of vehicles, including sedans, SUVs, and vans, allowing us to provide transportation for individuals, families, or larger groups. Let us know your preferences, and we will ensure that your city transfer is customized to your liking.",
  "Time is of the essence when exploring a city, and our city transfer service helps you make the most of your time. Our chauffeurs are experts in planning efficient routes and staying updated on traffic conditions, ensuring that you reach your destination in a timely manner. With our service, you can optimize your itinerary, visit multiple locations, and experience more of the city without worrying about parking or navigating public transportation.",
  "If you're visiting a city for sightseeing, our city transfer service is the perfect choice. Our chauffeurs can act as your personal tour guide, taking you to popular attractions, historical landmarks, and hidden gems. They can recommend the must-see sights, provide insights into the city's history and culture, and create an immersive experience for you. Sit back, relax, and enjoy the sights as our chauffeurs navigate the city for you.",
];

export const LUXURY_TRANSFER = [
  "Welcome to our luxury car rental in perth, A private car rental perth where we specialize in providing the ultimate luxury transportation experience. Whether you need transportation for a special occasion, a business event, or just want to ride in style, we've got you covered. Our luxury transportation services are designed to exceed your expectations. We offer a fleet of high-end vehicles to choose from, including luxury sedans, SUVs, and limousines, all of which are impeccably maintained and equipped with the latest technology and amenities. Our chauffeurs are professional and knowledgeable, with years of experience providing top-notch service to our clients.",
  <img src={carAudi} style={{ float: 'left', minWidth: '300px', maxWidth: '40%' }} />,
  "At our car chauffeur service, we understand that luxury transportation is about more than just a fancy car. That's why we offer a range of amenities to make your ride even more comfortable and enjoyable. From complimentary beverages and snacks to Wi-Fi and entertainment systems, we have everything you need to relax and enjoy the ride. Our luxury transportation services are perfect for weddings, proms, and other special events where you want to make a statement. We offer customized packages to fit your specific needs, including decorations, red carpet service, and even a personal concierge to handle all of your needs. But luxury transportation isn't just for special occasions. Our services are also ideal for business travel, where you want to make a lasting impression on your clients or partners. We offer corporate transportation packages that include dedicated account managers, streamlined billing, and customized service to meet your specific needs.",
  "Our fleet consists of the finest luxury vehicles, meticulously maintained to the highest standards. From sleek sedans to spacious SUVs, each vehicle is chosen for its elegance, sophistication, and cutting-edge features. Step into the lap of luxury and indulge in the comfort of our premium vehicles, which are equipped with plush leather interiors, state-of-the-art entertainment systems, and advanced amenities to ensure an extraordinary travel experience.",
  "Our chauffeurs are more than just drivers; they are professionals who epitomize the art of hospitality and personalized service. They possess extensive knowledge of the roadways, exhibit impeccable driving skills, and are committed to providing an exceptional experience. Our chauffeurs are meticulously trained to offer a seamless and discreet service, ensuring your safety, comfort, and privacy throughout your journey.",
  "We understand that luxury is not only about the vehicle you travel in but also the experience itself. From the moment our chauffeur greets you, you will be enveloped in an atmosphere of elegance and style. We pay meticulous attention to every detail, ensuring that your journey is characterized by refined aesthetics, luxurious amenities, and a level of service that sets us apart. Experience the epitome of luxury as we cater to your every need and desire.",
  "We believe that every client is unique, and we strive to tailor our services to your specific requirements. Whether you need transportation for a special occasion, a corporate event, or a leisurely exploration of the city, we can create a customized experience that aligns with your preferences. Our team works closely with you to understand your needs, offering suggestions and recommendations to curate a personalized journey that surpasses your expectations.",
  "As a luxury transfer service, we pride ourselves on offering VIP treatment to our esteemed clientele. From providing a red carpet entrance to arranging special requests, we go above and beyond to make you feel like a VIP throughout your journey. Enjoy priority access, preferential treatment, and a level of service that reflects your status and importance. Indulge in the ultimate luxury experience with our dedicated team at your service.",
  "We understand the need for privacy and confidentiality, especially for high-profile individuals and business executives. Our luxury transfer service ensures the utmost discretion and confidentiality during your journey. Our chauffeurs are trained to respect your privacy, ensuring that your conversations, meetings, and personal affairs remain completely confidential. Relax and conduct your business or enjoy your leisure time with complete peace of mind.",
  "We believe that luxury is not just about transportation; it's about creating memories that last a lifetime. Our goal is to provide you with an unforgettable experience that goes beyond transportation from point A to point B. From the ambiance of our vehicles to the attentiveness of our chauffeurs, every element is designed to make your journey extraordinary. Sit back, savor the moments, and let us take you on a luxurious adventure.",
];

export const TERMS_AND_CONDITIONS_LIST_1 = [
  "Alcohol:  While it is legal in WA for adults to consume alcohol while travelling in a licensed Small Charter Vehicle (SCV), it is subject to your driver explicitly approving it. You must ask BEFORE the travel booking day. Spillages are regarded as damage. Major spillages can result in termination of travel without refund.",
  "No Food or Smoking:  No food or chewing gum is permitted in our vehicles. Strictly no take-away food, even in unopened containers or bags. Wedding food by prior arrangement. Smoking is not permitted in, or around the vehicles.",
  "Damage:  Clients are liable for any damage caused to our vehicles either by the client themselves or a member of their travelling party. For wine, champagne, premix or beer, the minimum cleaning fee is A$250. For cosmetics, bodily fluids, vomit or chewing gum the minimum cleaning fee is A$750, plus the cost of any repair or replacement of vehicle parts and components.  Our cigarette smoke deodorisation fee is A$500 per car.",
  "Behaviour:  We reserve the right to not transport any individual who may be under the influence of alcohol or displaying signs of intoxication. Passengers who appear intoxicated or behave in an unacceptable manner (rude, anti-social, loud, boisterous, uncoordinated, disorderly, aggressive, etc.) will not be permitted to travel in the car. At the drivers, sole discretion. We may withdraw all vehicles immediately under these circumstances. No refunds!!",
  "Customer Delays:  If an appointment should go over the time stated in the booking due to customer-triggered delays, overtime rates may apply. This is to discourage conflicts with other customer bookings and to respect our driver's availability. Overtime is $1.50 per minute (per car) after the first 15mins, unless specified otherwise on your booking confirmation.",
  "Airport Arrival Delays:  Where possible, our drivers track inbound flights and arrive shortly after the flights actual landing.  Your transfer estimate includes some FREE waiting time (Domestic 30 mins; International 60 mins). This covers only airport-triggered delays (gate busy, baggage collection, lost baggage). It's calculated from the published time of landing. After this, we may charge overtime at $1.50 per minute (per car), unless specified otherwise on your booking confirmation.",
  "Grace Period:  Our aim is to arrive in advance of the agreed time (except for airport collections). Occasionally, events outside our control cause delays. Provided we arrive within 10 minutes of the scheduled pick-up time, it's deemed acceptable and within the terms of our agreement. If practical, we will try to extend your booking period to make up for any delay.  Beyond the grace period, we can refund payments or offer a gift voucher, at our discretion.",
  "Deposits:  All reservations must be confirmed with a non-refundable booking fee of either 30% of the total, or A$300 for weddings. We may request a higher amount for special events.",
  "Payment Terms:  Full payment must be made prior to the booking taking place, 50% deposit for tours to confirm booking with the balance payable 7 days prior to the tour.  We do not accept cheques. Payments can be made by EFT, Visa, MasterCard, American Express.",
  "Surcharges:  Additional fees may apply on public holidays, and for transfers between 12:01am - 5:59am.",
  "Merchant Fees: There may be additional processing fees for credit cards, debit cards and PayPal.",
  "Administration Fees:  Changes to booking dates, times and vehicle specification may incur an administration fee of between $30-$50, unless specified otherwise on your booking confirmation.",
  "Additional Fees: Any additional charges for delays, overtime, administration, surcharges, cleaning or damage may be automatically charged to your credit card and you hereby agree to this.",
  "Payment Default:  In the event of a payment default we shall engage a debt collection agency and/or law firm. Any commissions and legal costs will be added to the amount outstanding and form part of the debt. We reserve the right to charge an additional 5.5% admin fee on the cumulative balance, per week overdue.",
  "Maximum Passengers:  The maximum number of passengers in our vehicles is typically determined by the number of seatbelts. The 'comfortable' limit may be less.  Luggage may also reduce passenger limits such as during airport and cruise line transfers.",
  "Luggage:  All luggage must be safely stowed and strapped in the rear cargo area. Luggage cannot be carried in the passenger space. Large objects like surfboards, and snow boards can be accommodated in a trailer at additional cost.",
  "Social Media:  We reserve the right to use photos and video of our clients during bookings for social media promotion. Clients are welcome to explicitly opt-out via email.  We can remove/hide any items that you request.",
  "Unforeseen Circumstances: We cannot assume responsibility for any unforeseen circumstances beyond our control such as traffic, weather, illness, vehicle breakdown, emergency, etc. Should your requested vehicle be unavailable on the day, we reserve the right to substitute a similar vehicle.",
  "Limits Of Liabilities: In all cases, HR Chauffeur Service, it's owners, operators and chauffeurs maximum liability shall be limited to a full refund of monies paid for the contracted transfer in question. We will take due care, but no liability will be assumed beyond consumer laws in WA. You agree not to claim for liquidated damages, consequential loss or for any other eventuality.",
  "Acceptance Of Risk: You acknowledge that all travel involves an element of risk and that some tours offered may be adventurous in nature and may involve personal risk. You hereby assume all such risk and You, your estate, your family, heirs and assigns hereby release HR Chauffeur Service and the Tour Guide from all claims and causes of action whatsoever arising from any injury, death or other damages, both pecuniary and non-pecuniary, to You that may occur as a result of your participation in the tours offered.",
  // "Cancellations: Any cancellation must be made in writing or email and acknowledged by HR Chauffeur Service. The date on which the request to cancel is received by HR Chauffeur Service, and the booking event type, will determine the refund:"
];

export const TERMS_AND_CONDITIONS_LIST_2 = [
  "Weddings:  If advised 12-months before the wedding date, we will refund 100% of the remaining balance paid. Between 12-months and 6-months out, we will refund 50% of the balance paid. Within 6-months of your wedding date, there is NO refund and the full balance is due. Booking fees (deposits) are not refundable at any stage.",
  "Tours: Cancellations 60 days or more before tour departure receive the full amount paid, less any upfront or out of pocket expenses. Cancellation 59 to 31 days before departure refunds the balance (if paid in full), minus the booking fee (deposit) of 30%. Cancellation less than 30 days before departure receive NO refund, and the full balance is payable.  You are encouraged to obtain travel insurance. In certain circumstances, this will cover the cost of cancellation.",
  "Charters: Cancellations 30 days or more before tour departure receive the full amount paid, less any upfront or out of pocket expenses. Cancellation 29 to 8 days before departure refunds the balance (if paid in full), minus the booking fee (deposit) of 30%. Cancellation less than 7 days before departure receive NO refund, and the full balance is payable."
]



export const META_AIRPORT_TITLE = ["Perth Airport Transfer Chauffeur Services | HR Chauffeur"]
export const META_CORPORATE_TITLE = ["Perth Corporate Transfers Chauffeur services | HR Chauffeur"]
export const META_HOURLY_TITLE = ["Hourly Private Chauffeur Services HR Chauffeur Cost per Hour"]
export const META_CITYTRANSPORT_TITLE = ["Car Hire for Tours in Perth | Tour Rental Car Services"]
export const META_PTOP_TITLE = ["Point-to-Point Transport & Pick and Drop Service in Perth"]
export const META_LUXURY_TITLE = ["Luxury Car Chauffeur Perth | Car Hire & Transport Services"]


export const testimonials = [
  {
    name: 'Jessica ',
    comment: 'I had an excellent experience with the HR Chauffeur booking service. The driver was punctual, courteous, and the vehicle was in pristine condition. I highly recommend HR Chauffeur services for a hassle-free and comfortable ride.',
    imageSrc: 'path/to/john-doe.jpg',
  },
  {
    name: 'Carol',
    comment: 'HR Chauffeur provided us with excellent service! Would highly recommend HR Chauffeur and especially Airport transfer. ',
    imageSrc: 'path/to/john-doe.jpg',
  },
  {
    name: 'Natalie',
    comment: "Exceptional service! From The HR chauffeur, chauffeur arrived on time, and the vehicle was luxurious and well-maintained. The driver's professionalism and safe driving made my trip stress-free. I'll certainly use this service again.",
    imageSrc: 'path/to/john-doe.jpg',
  },
  {
    name: 'Phil',
    comment: "The chauffeur booking service provided me with a seamless experience by HR Chauffeur. The driver was professional, friendly, and knew the city well. The service exceeded my expectations, and I'll definitely use it again for my future travels. Thank you HR Chauffeur.",
    imageSrc: 'path/to/john-doe.jpg',
  },
  
];