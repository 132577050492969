import { SelectCarDropDown } from "../../../../constants";
import Map from "../../../common/GoogleRouteMarker";
import CarCards from "../CarCards";
import "./style.scss";

const Step2 = (props) => {
  const { personalData, selectCard } = props;
  return (
    <div className="step2-wrapper">
      <div className="step2-left">
        <p className="steps-heading">Map</p>
        <Map
          start={personalData["startLocation"].value}
          end={personalData["endLocation"].value}
        />
      </div>

      <div className="step2-bottom">
        <p className="steps-heading">Choose Your Ride</p>
        {personalData["selectedCar"]?.error && (
          <div className="error-text">Please Choose a Ride</div>
        )}
        <CarCards
          cardsList={SelectCarDropDown}
          selectCard={selectCard}
          personalData={personalData}
          getkey="selectedCar"
        />
      </div>
    </div>
  );
};

export default Step2;
