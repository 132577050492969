import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

// const firebaseConfig = {
//   apiKey: 'YOUR_API_KEY',
//   authDomain: 'YOUR_AUTH_DOMAIN',
//   projectId: 'YOUR_PROJECT_ID',
//   storageBucket: 'YOUR_STORAGE_BUCKET',
//   messagingSenderId: 'YOUR_MESSAGING_SENDER_ID',
//   appId: 'YOUR_APP_ID',
// };


const firebaseConfig = {
  apiKey: "AIzaSyCShDm3SgAuAbUzUS6ipFSh_y4RDjTmyNs",
  authDomain: "cabservices-fe95e.firebaseapp.com",
  projectId: "cabservices-fe95e",
  storageBucket: "cabservices-fe95e.appspot.com",
  messagingSenderId: "614507824474",
  appId: "1:614507824474:web:5a9c12b3d1348611c75f63",
  measurementId: "G-QSEM1413GJ"
  };

firebase.initializeApp(firebaseConfig);

console.log('Firebase initialized successfully');

export const db = firebase.firestore();
