import { PropaneSharp } from "@mui/icons-material";
import React from "react";
import { CARDS_DATA } from "../../../constants";
import "./style.scss";

const Cards = ({ data, index }) => {
  return (
        <div className="card_wrapper">
          <div>
            <span className="icons-wrapper">{data.image}</span> 
            <h5>{data.name}</h5>
            <p className="card_details">{data.details}</p>
            <p className="card_number">{`0${index}`}</p>
          </div>
        </div>
  );
};

export default Cards;
