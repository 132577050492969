import React from 'react';
import './style.scss';

const Header = ({ heading = "",nextSubHeading="", subHeading, secondaryHeading, ...props }) => {
    return (
        <div className='Header_wrapper'>
            <p className='primary_heading'>{heading}</p>
            <p className='secondary_heading'>{secondaryHeading}</p>
            <p className='sub_heading'>{subHeading}</p>
            <h1 className='sub_heading sub_heading_small'>{nextSubHeading}</h1>
        </div>
    );
};

export default Header;