const DetailsBox = (props) => {
  const { personalData, firstSection, secondSection, thirdSection } = props;
  return (
    <div className="journey-wrapper">
      <div className="journey-top">
        <p className="journey-subheading"></p>
        {!!firstSection &&
          firstSection.map((item) => {
            return (
              <div className="container-1">
                <span className="yellow">{item["key"]}:&nbsp;</span>
                <span>{personalData[item["value"]].value.name}</span>
              </div>
            );
          })}
      </div>
      <div className="journey-second">
        {/* <p className="journey-subheading"></p> */}
        {!!secondSection &&
          secondSection.map((item) => {
            return (
              <div className="container-1">
                <span className="yellow">{item["key"]}:&nbsp;</span>
                <span>
                  {item["suffix"] ? `${item["suffix"]} ` : ""}
                  {personalData[item["value"]]}
                </span>
              </div>
            );
          })}
      </div>
      <div className="journey-third">
        <p className="journey-subheading"></p>
        {!!thirdSection &&
          thirdSection.map((item) => {
            return (
              <div className="container-1">
                <span className="yellow">{item["key"]}:&nbsp;</span>
                <span>{`${personalData[item["value"]].value} ${
                  item.suffix ? item.suffix : ""
                }`}</span>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default DetailsBox;
