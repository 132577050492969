import React from "react";
import PlacesAutocomplete from "react-places-autocomplete";
import "./style.scss";

export default class LocationSearchInput extends React.Component {
  render() {
    const { placeHolder, className, personalData } = this.props;
    return (
      <>
        <PlacesAutocomplete
          value={this.props.address.name}
          onChange={this.props.handleChange}
          onSelect={this.props.handleSelect}
          searchOptions={{ componentRestrictions: { country: ["au"] } }}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading,
          }) => (
            <div className="google-input">
              <input
                {...getInputProps({
                  placeholder: placeHolder,
                  className: `location-search-input ${className}`,
                })}
              />
              <div className="autocomplete-dropdown-container">
                {loading && <div>Loading...</div>}
                {suggestions.map((suggestion) => {
                  const className = suggestion.active
                    ? "suggestion-item--active"
                    : "suggestion-item";
                  // inline style for demonstration purpose
                  const style = suggestion.active
                    ? { backgroundColor: "#fa9736", cursor: "pointer" }
                    : { backgroundColor: "#212121", cursor: "pointer" };
                  return (
                    <div
                      {...getSuggestionItemProps(suggestion, {
                        className,
                        style,
                      })}
                    >
                      <span>{suggestion.description}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </PlacesAutocomplete>
        {personalData?.error && personalData.focused && (
          <p
            className={
              personalData?.error && personalData.focused ? "error-text" : ""
            }
          >
            Please Enter a Valid Location
          </p>
        )}
      </>
    );
  }
}
