import { PAYMENT_OPTIONS } from "../../../../constants";
import Payment from "../../../../Payment";
// import ModalRendererNew from "../../../common/modalNew";
import CarCards from "../CarCards";
import "./style.scss";

const Step4 = (props) => {
  const { personalData, selectCard } = props;
  return (
    <div className="step4-wrapper">
      <div className="step4-card-payment-wrapper">
        <p className="steps-heading">Payment</p>
        {/* <SelectPicker
            placeholder="Select Car Type"
            name="carType"
            // onChange={carTypeHandler}
            value={personalData["paymentMethod"]}
            data={PAYMENT_OPTIONS}
            style={{ width: 300 }}
          /> */}
        {/* <div className="input-container">
          <select name="paymentMethod" id="paymentMethod">
            {PAYMENT_OPTIONS.map((item) => {
              return <option value={item.key}>{item.value}</option>;
            })}
          </select>
        </div> */}
        <CarCards
          cardsList={PAYMENT_OPTIONS}
          selectCard={selectCard}
          personalData={personalData}
          getKey="paymentMethod"
        />
        {/* <ModalRendererNew /> */}
        {/* <Payment data={personalData} /> */}
      </div>
    </div>
  );
};

export default Step4;
