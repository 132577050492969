import { useState } from "react";
import { Modal, ButtonToolbar, Button, Loader, Placeholder } from "rsuite";
import {
  PERSONALDATA_KEYS,
  ReturnTable,
  calculateDistance,
  checkIfValid,
  ifAirportLocationValidation,
  returnCalculatedPrice,
} from "../../../utils/commonutils";
import popUpAlert from "../alert";
import {
  JOURNEY_OBJECT_FIRST,
  JOURNEY_OBJECT_SECOND,
  JOURNEY_OBJECT_THIRD,
  JOURNEY_OBJECT_THIRD_HOURLY,
  SelectCarDropDown,
  TRIPOPTION_OBJECT,
} from "../../../constants";
import { ContactUs } from "../emailSend";
import { Link } from "react-router-dom";
import DetailsBox from "../DetailsBox";
import moment from "moment";
import { db } from "../../../firebase";



const QuoteModal = ({ formData = {}, address }) => {
  const [open, setOpen] = useState(false);
  const [rows, setRows] = useState(0);
  const handleClose = () => setOpen(false);
  const data = formData;

  // const handleEntered = () => {
  //   setTimeout(() => setRows(80), 2000);
  // };

  const getQuote = async () => {
    // let emailParams = {};
    let ifValid = checkIfValid(formData, address);

    if (ifValid.error) {
      popUpAlert("error", ifValid.message, ifValid.messageHeading);
    } else if (!ifAirportLocationValidation(formData)) {
      popUpAlert(
        "error",
        "Either Start or End Location should be an Airport",
        "Location Error"
      );
    } else {
      // Object.keys(formData).map((key) => {
      //   return (emailParams[key] = formData[key].name || formData[key]);
      // });
      let calculate = await calculateDistance(formData);
      let selectedCar = SelectCarDropDown.filter((item) => {
        return item.type === formData.carType;
      });
      let cost = returnCalculatedPrice(
        formData,
        calculate.distance,
        selectedCar[0]
      );

      data.distance = calculate.distance;
      data.duration = calculate.duration;
      data.cost = cost;

      console.log("dataaa", data);

      setOpen(true);
      handleCta();
    }
  };

  // const handleContact = () => {
  //   const returnedTable = ReturnTable(data);
  //   console.log("hello", returnedTable);
  //   // ContactUs()
  // };

   
  const handleCta =() => {
    try {
      // console.log(startAddress)
      db.collection("Chauffeur44").add(data);
      console.log(' Quote Data stored successfully.');
      
      return ; // Indicates successful storage
    } catch (error) {
      console.error('Error storing data in Firestore:', error);
      return false; // Indicates an error occurred
    }
   }


  return (
    <>
      <ButtonToolbar>
        <Button className="nxt-btn" onClick={getQuote}>
          Get Quote
        </Button>
      </ButtonToolbar>

      <Modal open={open} onClose={handleClose}>
        <Modal.Header>
          <Modal.Title>Quotation</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{marginTop:"10px"}}>
          <div className="quotation-modal-table-wrapper">
            <table className="quotation-modal-table">
              <thead>
                <tr>
                  <th colspan={2}>Your Journey Details</th>
                </tr>
              </thead>
              <tbody>
                {/* <tr>
                  <td className="first-child">Name</td>
                  <td className="second-child">{data?.name}</td>
                </tr>
                <tr>
                  <td className="first-child">Phone</td>
                  <td className="second-child">{data?.phone}</td>
                </tr> */}
                <tr>
                  <td className="first-child">Start Location</td>
                  <td className="second-child">{data?.startLocation?.name}</td>
                </tr>
                <tr>
                  <td className="first-child">End Location</td>
                  <td className="second-child">{data?.endLocation?.name}</td>
                </tr>
                <tr>
                  <td className="first-child">Date & Time</td>
                  <td className="second-child">
                    {moment(data?.date).format("MM/DD/YYYY, hh:mm A")}
                  </td>
                </tr>
                <tr>
                  <td className="first-child">Transfer Type</td>
                  <td className="second-child">
                    {PERSONALDATA_KEYS[data?.transferType]}
                  </td>
                </tr>
                <tr>
                  <td className="first-child">Car</td>
                  <td className="second-child">
                    {PERSONALDATA_KEYS[data?.carType]}
                  </td>
                </tr>
                <tr>
                  <td className="first-child">Trip Option</td>
                  <td className="second-child">
                    {TRIPOPTION_OBJECT[data?.tripOption]}
                  </td>
                </tr>
                {data?.tripOption === "hourly" && (
                  <tr>
                    <td className="first-child">Hours</td>
                    <td className="second-child">{data?.hours} Hours</td>
                  </tr>
                )}
                <tr>
                  <td className="first-child">Duration</td>
                  <td className="second-child">{data?.duration}</td>
                </tr>
                <tr>
                  <td className="first-child">Distance</td>
                  <td className="second-child">
                    {data?.distance?.toFixed(0)} KM
                  </td>
                </tr>
                <tr>
                  <td className="first-child">Cost</td>
                  <td className="second-child">AUD {data?.cost?.toFixed(0)}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <a href="tel:+61455031641">
            <Button
              // onClick={handleContact}
              // onClick={handleCta}
              appearance="primary"
              className="nxt-btn"
            >
              Call Us
            </Button>
          </a>
          {/* <Link to="/book-chauffeur">
            <Button appearance="subtle">Hire Now</Button>
          </Link> */}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default QuoteModal;
