import React from "react";
import Header from "../../common/componentHeader";
import carAudi from "../../../assets/images/car-audi.png";
import Faq from "../../dashboards/Faq";
import Bookbanner from "../bookbanner";
import { Helmet } from "react-helmet-async";
import { HIRE } from '../../../constants';
import { Link } from "react-router-dom";

const NewPage = ({
  title = '',
  discription = '',
  url = '',
  heading = '',
  subheading = '',
  paragraphs = [],
  nextSubHeading = "",
  image = ""
}) => {
  return (
    <div
      className="h_100 w_100 aboutus_section transitionIn-animation pb_20"
      id="aboutus"
    >

      <Helmet>
        <title>{title}</title>
        <meta name="description" content={discription} />
        <link rel="canonical" href={url} />
      </Helmet>

      <div className="head-background">
        <Header secondaryHeading={heading} subHeading={subheading} nextSubHeading={nextSubHeading} />
      </div>
      <div className='button-link' style={{display:"flex", justifyContent:"center" }}>

        <Link
          name={HIRE}
          className="outline_btn hire_btn hire_btn_card"
          to="/book-chauffeur"
          style={{ minWidth: '250px', textAlign: 'center', marginTop:"0px" }}
        >
          {HIRE}
        </Link>
      </div>
      <div className='w_80 p_20 '>
        <div>
          {/* <img style={{ float:'right'}} src={carAudi}/> */}
          {paragraphs.map(item => {
            return <p className="aboutus-description">{item}</p>
          })}
        </div>

      </div>
      <Bookbanner />
      <Faq />
    </div>
  );
};

export default NewPage;
