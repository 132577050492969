import React, { lazy, Suspense, useEffect, useState } from "react";
// import Loader from "../common/loader";
import Advantages from "./advantages/index2";
import Banner from "./banner";
import Services from "./banner/services";
import Working from "./working";
// import ReactDOM from "react-dom";
import "./style.scss";
import QuotationForm from "../common/quotationForm";
// import ServicesOffer from "./services";
// import FlushExample from "./Faq";
import ServicesOffer2 from "./servielinks";
import { Helmet } from 'react-helmet-async';
import Faq from "./Faq";
import TestimonialCarousel from "./TestimonialCarousel";
import ControlledCarousel from "./TestimonialCarousel";
import { testimonials } from "../../constants";
// import FlushExample from "./Faq/Accordian";



// const QuotationForm = lazy(() => import('../common/quotationForm'));
// const Advantages = lazy(() => import('./advantages/index2'));
// // const Banner = lazy(() => import('./banner'));
// const Services = lazy(() => import('./banner/services'));
// const Working = lazy(() => import('./working'));
// const ServicesOffer2 = lazy(() => import('./servielinks'));
// const Faq = lazy(() => import('./Faq'));





const Dashboard = () => {
  const [isLoading, setIsLoading] = useState(true);






  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);
    return () => clearTimeout(timer);
  });
  return (
    <>
      <Helmet>
        <title>Private Car Chauffeur Service in Perth - HR Chauffeur</title>
        <meta name="description" content="Discover the best private car chauffeur service in Perth. Choose HR Chauffeur company and enjoy premium chauffeur-driven experiences." />
        <link rel="canonical" href="https://www.hr-chauffeur.com.au/" />
      </Helmet>
      {/* <Payment /> */}
      {/* {isLoading && <Loader />} */}
      <div
        className={
          isLoading
            ? "dashboard-wrapper"
            : "transitionIn-animation dashboard-wrapper"
        }
      >


        {/* <Suspense fallback={<div>Loading...</div>}>
          <Banner />
        </Suspense> */}
        <Banner />
        <div className="banner-quoation-wrapper">


          {/* <Suspense fallback={<div>Loading...</div>}>
            <QuotationForm />
          </Suspense> */}
          <QuotationForm />

        </div>

        {/* <TestimonialCarousel */}
        {/* //  testimonials={testimonials} */}
         {/* /> */}

        <Services />
        {/* <Suspense fallback={<div>Loading...</div>}>
        <Services />
      </Suspense> */}

      <Advantages />
        {/* <Suspense fallback={<div>Loading...</div>}>
        <Advantages />
      </Suspense> */}

      <ServicesOffer2 />
      {/* <Suspense fallback={<div>Loading...</div>}>
        <ServicesOffer2 />
      </Suspense> */}

      <Working />
      {/* <Suspense fallback={<div>Loading...</div>}>
        <Working />
      </Suspense> */}

      <ControlledCarousel testimonials={testimonials}/>


      <Faq />
      {/* <Suspense fallback={<div>Loading...</div>}>
        <Faq />
      </Suspense> */}
      </div>
    </>
  );
};

export default Dashboard;
