import React from "react";
import { LEARN, HOW_IT, WORKS, CARDS_DATA } from "../../../constants";
import Cards from "../../common/cards";
import Header from "../../common/componentHeader/index2";
import "./style.scss";

const Working = () => {
  return (
    <div className="h_100 services_section transitionIn-animation pb_20" id="howitworks">
      <div className="w_80">
        <Header heading={LEARN} secondaryHeading={HOW_IT} subHeading={WORKS} />
        <div className="cards_wrapper">

          {CARDS_DATA.map((item, index) => {
            return (
              <>
                <Cards data={item} index={index + 1} />
              </>
            );
          })}
        </div>
        <h3 class="primary_heading">Book Easy, Quick and Hassle-free!</h3>
          
          <p className='text-content-2'>Booking private Chauffeured Car in Perth is easy and quick. Keeping in pace with the customers' demand and changing times, we offer an online booking service. That makes the entire process fast and hassle-free. All you have to do is visit our website and fill out the form. One of our representatives will get in touch with you to share the details. Booking a car in Perth will take up to a few minutes.

Let your search for one of the best Chauffeured Car companies in Perth end with us. Be it personal, 
business or leisure trip, HR-Chauffeur is here to serve you. 
</p>
      </div>
    </div>
  );
};

export default Working;
