import Swal from "sweetalert2";
import "./style.scss";

const popUpAlert = (icon, message, title) => {
  Swal.fire({
    title: title,
    text: message,
    icon: icon,
    confirmButtonText: "Close",
  }).then(() => {
    if (icon === "success") {
      window.location.pathname = "/";
    }
  });
};

export default popUpAlert;