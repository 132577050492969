import React from "react";
import { Link } from "react-router-dom";
import { HIRE } from "../../../constants";
import ModalRenderer from "../modal";
import "./style.scss";

const CardRenderer = ({ data = [] }) => {
  return (
    <div className="cards_wrapper">
      <div className="cards">
        {data.map((item, index) => {
          return (
            <div className="single__card">
              <p className="service_provided">{item.service}</p>
              {/* <p className="ride_details">{item.ride_details}</p> */}
              <h5 className="car_type">{item.carType}</h5>
              <p className="ride_details">{item.car}</p>
              <p className="ride_details">{item.car1}</p>
              <p className="ride_details">{item.car2}</p>
              
              <p className="ride_amount">{item.amount}</p>
              <img className="card__image" src={item.url} alt={item.alt} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CardRenderer;
