import "./style.scss";

const CarCards = (props) => {
    const { cardsList, selectCard, personalData, getKey = "selectedCar" } = props
    
    return (
        <div className={`car-card-wrapper ${personalData[getKey]?.error ? "error-wrapper" : "" }`}>
        {cardsList?.map(item => {
            return (
                <>
                <div className={`car-card ${(personalData[getKey]?.value?.value === item?.value || personalData[getKey] === item?.value) ? "car-selected" : ""}`} onClick={() => selectCard(item, getKey)}>
                    {item.image && <img src={item?.image} alt="This is car" />}
                    {item.svg && item.svg}
                <p>{item?.label}</p>
                </div>
                </>
            )
        })}
        </div>
    )
}

export default CarCards;