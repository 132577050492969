import React from "react";
import Header from "../../common/componentHeader";
import {
  TERMS_AND_CONDITIONS_LIST_1,
  TERMS_AND_CONDITIONS_LIST_2,
} from "../../../constants";
import "./style.scss";

const TermsAndConditions = ({
  heading = "",
  subheading = "",
  paragraphs = [],
  nextSubHeading = "",
  image = "",
}) => {
  return (
    <div
      className="h_100 w_80 terms_and_condition_section transitionIn-animation pb_20"
      id="aboutus"
    >
      <Header
        secondaryHeading={heading}
        subHeading={subheading}
        nextSubHeading={nextSubHeading}
      />
      <div>
        <h3 className="text-28px">
          *Your online booking is subject to final acceptance and Confirmation
          by HR Chauffeur Service*
        </h3>
        <p className="terms-and-condition-description">
          We're always happy to clarify any questions you have.
        </p>
        <ol type="1" className="ol-style">
          {TERMS_AND_CONDITIONS_LIST_1.map((item) => {
            return <li>{item}</li>;
          })}
        </ol>
        <p className="terms-and-condition-description">
          Cancellations: Any cancellation must be made in writing or email and
          acknowledged by HR Chauffeur Service. The date on which the request to
          cancel is received by HR Chauffeur Service, and the booking event
          type, will determine the refund:
        </p>
        <ol type="a" className="ol-style">
          {TERMS_AND_CONDITIONS_LIST_2.map((item) => {
            return <li>{item}</li>;
          })}
        </ol>
      </div>
    </div>
  );
};

export default TermsAndConditions;
