import { ADVANTAGE, ADVANTAGE_CARD_LIST, WHAT_ARE_THE } from "../../../constants"
import Card from "../../common/card"
import Header from "../../common/componentHeader/index2"
import { Link } from "react-router-dom";
import { HIRE } from "../../../constants";
import "./style.scss";


const Advantages = () => {
    return (
        <div className='h_100 w_80 services_section transitionIn-animation pb_20' id="advantages">





            <Header heading={ADVANTAGE} secondaryHeading={WHAT_ARE_THE} subHeading={ADVANTAGE} />

            <div className='w_100 p_20 '>
                <div className="advantage_card_wrapper">
                    {ADVANTAGE_CARD_LIST.map(item => (
                        <Card data={item} />
                    ))}
                </div>
                <div className="advantage-card-box">
                    <Link
                        name={HIRE}
                        className="outline_btn hire_btn hire_btn_card"
                        to="/book-chauffeur"
                    >
                        {HIRE}
                    </Link>

                </div>
                <h3 class="primary_heading">Trust Matters!</h3>
          
            <p className='text-content-2'>Being one of the trusted and experienced Chauffeured Car companies in Perth, we make sure you have access to hassle-free services. We have years of industry experience when it comes to Chauffeured Car services. Let us help you make your Perth trip memorable. </p>
                <h3 class="primary_heading">Your Safety is Our Priority!</h3>
          
            <p className='text-content-2'>When you hire a car to rent with us, you can traverse anywhere in and around Perth without worrying about safety. We run background checks on all our chauffeurs. We train our candidates regularly so that our customers can have safe trips. We will share the details of your valid driver before the pick-up time. Our chauffeurs know the routes and ensure you feel safe and secure. </p>
            </div>
        </div>
    )
}

export default Advantages