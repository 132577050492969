// import { Card } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { ABOUT, ABOUT_US_DESCRIPTION, ADVANTAGE_CARD_LIST, SERVICES, SERVICES_CARD_LIST, SERVICES_OFFERED_1, SERVICES_OFFERED_2, SERVICES_OFFERED_3, US, WE_OFFERED } from "../../../constants";
import Card from "../../common/cardServices";
import { HIRE } from '../../../constants';
// import Cards from "../../common/cards";
import Header from "../../common/componentHeader";
import "./style.scss";
import { Helmet } from "react-helmet-async";

const ServicesOffer = () => {
  return (
    <div
      className="h_100 w_100 aboutus_section transitionIn-animation pb_20"
      id="aboutus"
    >
      <Helmet>
        <title>Chauffeured car services in Perth | HR Chauffeur</title>
        <meta name="description" content="Perth Chauffeur Hire. We offer a range of luxury chauffeured cars for airport transfers, corporate hire, weddings, tours and special events.
" />
        <link rel="canonical" href="https://www.hr-chauffeur.com.au/services" />
      </Helmet>
      <div className="head-background">
        <Header heading={"SERVICES"} secondaryHeading={"SERVICES"} subHeading={WE_OFFERED} />
      </div>
      <div className="advantage_card_wrapper services w_80">
        {SERVICES_CARD_LIST.map(item => (
          <Link to={item.link}><Card data={item} /></Link>
        ))}
      </div>
      {/* <div>
        <p className="aboutus-description">{SERVICES_OFFERED_1}</p>
        <p className="aboutus-description">{SERVICES_OFFERED_2}</p>
        <p className="aboutus-description">{SERVICES_OFFERED_3}</p>
      </div> */}

      <div className="w_80" >
      <h3 class="primary_heading">Wine Tour Perth!</h3>
      <p className='text-content-2' style={{ marginBottom: "0px" }}> Experience a fun day while sitting in a luxury chauffeured car, dine in upscale restaurant and award winning and wine fines that Perth region have for you.  </p>
      <p className='text-content-2' style={{ marginBottom: "0px" }}> HR Chauffeur provides a full service where we come to your door and take you to your destination. You don't have to worry about finding a driver, walking, or catching a bus or train. Our skilled chauffeur will pick you up from one or more places and safely bring you back to where you want to go after a great day out. </p>
      <p className='text-content-2' style={{ marginBottom: "0px" }}> Indulge in the ultimate wine-tasting experience with our Wine Tour Chauffeur in Perth! </p>
      <p className='text-content-2' style={{ marginBottom: "0px" }}> Sit back, relax, and savor the finest wines of the region as our expert chauffeur takes you on a luxurious journey through picturesque vineyards. Book your exclusive wine tour with HR Chauffeur and elevate your tasting experience! </p>


      <h3 class="primary_heading">Wedding Tour Perth!</h3>
      <p className='text-content-2' style={{ marginBottom: "0px" }}> Step into a world of refined luxury with HR Chauffeur, your ultimate choice for wedding transfer in Perth. Whether you're preparing for the most important day of your life or celebrating love, our chauffeured car services are tailored to make your wedding day an unforgettable experience. </p>
      <p className='text-content-2' style={{ marginBottom: "0px" }}> Wedding transfer is one of the most important factors as it is required to bride and groom, both to be at destination on time. Reserving chauffeur well in advance for the big day is crucial but it all starts with choosing the right private luxury chauffeur company - HR Chauffeur. </p>
      <p className='text-content-2' style={{ marginBottom: "0px" }}> HR Chauffeur have a complete wedding car transfer plans in Perth that are prepared in that way so you don’t need to stress for organizing anything. Our wedding transfer services get you to your reception on time and in complete comfort with a dedicated vehicle of your choice. </p>

      <div className="button-box" style={{justifyContent:"center", display:"flex"}}>
        <Link
          name={HIRE}
          className="outline_btn hire_btn hire_btn_card"
          to="/book-chauffeur"
          style={{ textAlign: 'center', zIndex: "99", margin: '10px' }}
        >
          {HIRE}
        </Link>
      </div>
    </div>
    </div>

  );
};

export default ServicesOffer;
