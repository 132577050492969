import React from 'react';
import { useEffect } from "react";
import Header from "../../common/componentHeader";
import "./style.scss";
import { Helmet } from 'react-helmet';

// import Lead from '../Lead';




function Thankyou() {

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag('js', new Date());
    gtag('event', 'conversion', { 'send_to': 'AW-11336809964/gTI0CN2EiukYEOz7550q' });
  }, []);



  
  return (
    <div
      className="h_100 w_100 aboutus_section transitionIn-animation pb_20"
      id="aboutus" >
      <Helmet>
      {
      function setupGoogleAnalytics() {
        window.dataLayer = window.dataLayer || [];
        function gtag() {
          window.dataLayer.push(arguments);
        }
        gtag('js', new Date());
        
        return gtag('event', 'conversion', { 'send_to': 'AW-11336809964/gTI0CN2EiukYEOz7550q' });
      }
      
      }
      </Helmet>
      <div className="head-background">
        <Header secondaryHeading={"Thank"} subHeading={"You"} />
      </div>
      <div style={{ display: "block", textAlign: "center" }}>
        <p className="aboutus-description" style={{ fontSize: "25px" }}>{"Thank You!"}</p>
        <p className="primary_heading">{" Your Booking has been Confirmed. You will get the confirmation mail soon."}</p>
        {/* <p className="aboutus-description">{ "Confirmed"}</p> */}

      </div>

      {/* <Lead/> */}



    </div>
  )
}

export default Thankyou;
