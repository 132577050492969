import React from "react";
import {
  HIRE_TEXT,
  LUXURY_TEXT,
  CLICK_TEXT,
  CAR_TEXT,
  HOURLY_RATE,
  MINIMUM,
  HIRE,
  BOOK_TEXT,
  DRIVE,
  DREAM,
  WITH_US,
  OUR_COMMITMENT,
  QUALITY,
  NONE,
  WITH,
  US_DRIVE,
  SUV_RATE,
  VAN_RATE,
} from "../../../constants";
import "./style.scss";
import carImage from "../../../assets/images/car.webp";
import carImage2 from "../../../assets/images/cars/car6.webp";
import carImage3 from "../../../assets/images/cars/car12.webp";

import ModalRenderer from "../../common/modal/index3";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { Link } from "react-router-dom";
import QuotationForm from "../../common/quotationForm";

const Banner = ({ onSelect, activeKey, items, ...props }) => {
  const openModal = () => {
    <ModalRenderer open={true} />;
  };

  const moveToRoute = () => {
    window.location.href = "/book-chauffeur";
  };

  return (
    <div className="container_element h_100 banner_section" id="home">
      <div className="w_80 d_flex banner">
        <div className="image_wrapper">
          <Carousel
            autoPlay={true}
            showThumbs={false}
            interval={4000}
            infiniteLoop={true}
            showArrows={false}
            showStatus={false}
            showIndicators={false}
            stopOnHover={false}
            dynamicHeight={true}
            //   width="500px"
          >
            <div className="banner_carousel">
              <div className="banner_text_wrapper">
                <p className="banner_text">{HIRE_TEXT}</p>
                <p className="banner_text">
                  {LUXURY_TEXT} <span className="yellow">{CAR_TEXT}</span>
                </p>
                <p className="banner_text">{CLICK_TEXT}</p>
                <div className="charges">
                  <p className="hourly_rate">{HOURLY_RATE}</p>
                  <p className="minimum_text">{MINIMUM}</p>
                </div>
                <div className="hire_section">
                  <Link
                    name={HIRE}
                    className="outline_btn hire_btn"
                    to = "/book-chauffeur"
                  >
                    {HIRE}
                  </Link>
                </div>
              </div>
              <div className="car_image_wrapper">
                <img src={carImage2} alt="Mercedes Benz Sedan Chauffeur"/>
              </div>
            </div>
            <div className="banner_carousel">
              <div className="banner_text_wrapper">
                <p className="banner_text">{DRIVE}</p>
                <p className="banner_text">{DREAM}</p>
                <p className="banner_text yellow">
                  {" "}
                  {WITH} {US_DRIVE}
                </p>
                {/* <p className="banner_text">{CLICK_TEXT}</p> */}
                <div className="charges">
                  <p className="hourly_rate">{SUV_RATE}</p>
                  <p className="minimum_text">{MINIMUM}</p>
                </div>
                <div className="hire_section">
                <Link
                    name={HIRE}
                    className="outline_btn hire_btn"
                    to = "/book-chauffeur"
                  >
                    {HIRE}
                  </Link>
                </div>
              </div>
              <div className="car_image_wrapper">
                <img src={carImage} alt="SUV chauffeur Luxury car" />
              </div>
            </div>
            <div className="banner_carousel">
              <div className="banner_text_wrapper">
                <p className="banner_text">{OUR_COMMITMENT}</p>
                <p className="banner_text">
                  <span className="yellow">{QUALITY}</span>
                </p>
                <p className="banner_text">{NONE}</p>
                <div className="charges">
                  <p className="hourly_rate">{VAN_RATE}</p>
                  <p className="minimum_text">{MINIMUM}</p>
                </div>
                <div className="hire_section">
                <Link
                    name={HIRE}
                    className="outline_btn hire_btn"
                    to = "/book-chauffeur"
                  >
                    {HIRE}
                  </Link>
                </div>
              </div>
              <div className="car_image_wrapper">
                <img src={carImage3} alt="Mercedes BenzMUV | V Class" />
              </div>
            </div>
          </Carousel>
          {/* <img className="banner_car_image" alt="car-image" src={carImage3} /> */}
        </div>
        {/* <div className="banner-quoation-wrapper">
          <QuotationForm/>
        </div> */}
      </div>
    </div>
  );
};

export default Banner;
