
import React from 'react'
import FlushExample from './Accordian'
import Header from "../../common/componentHeader/index2";

function Faq() {
  return (
    <div
      className="h_100 w_80 aboutus_section transitionIn-animation pb_20"
      id="aboutus"
    >
    {/* <div className="head-background"> */}
      <Header heading={"FAQ"} secondaryHeading={"FREQUENTLY ASKED"} subHeading={"QUESTION"} />
     
      <div className="advantage_card_wrapper services w_100">
        <FlushExample/>
        </div>
    </div>
  )
}

export default Faq
