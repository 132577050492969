// import { Card } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { ABOUT, ABOUT_US_DESCRIPTION, ADVANTAGE_CARD_LIST, SERVICES, SERVICES_CARD_LIST, SERVICES_OFFERED_1, SERVICES_OFFERED_2, SERVICES_OFFERED_3, US, WE_OFFERED } from "../../../constants";
// import Card from "../../common/cardServices";
// import Cards from "../../common/cards";
import Header from "../../common/componentHeader/index2";
import "./style.scss";
import Card2 from "../../common/cardServices/index2";

const ServicesOffer2 = () => {
  return (
    <div
      className="h_100 w_80 aboutus_section transitionIn-animation pb_20"
      id="aboutus"
    >
  
      <Header heading={"SERVICES"} secondaryHeading={"SERVICES"} subHeading={WE_OFFERED} />
      
      <div className="advantage_card_wrapper services w_100">
        { SERVICES_CARD_LIST.map(item=> (
          <Link to = {item.link}><Card2 data={item}/></Link>
            
        ))}
        </div>
      {/* <div>
        <p className="aboutus-description">{SERVICES_OFFERED_1}</p>
        <p className="aboutus-description">{SERVICES_OFFERED_2}</p>
        <p className="aboutus-description">{SERVICES_OFFERED_3}</p>
      </div> */}
      <h3 class="primary_heading">Chauffeured Car at Affordable Rates!</h3>
       <p className='text-content-2'>As a leading private Chauffeured Car company in Perth, we understand that customers look for rates that are light on pocket. We pay attention to this. Thus, we offer competitive rates for our Chauffeured Car services. Let the reason for booking a car be any; we strive to bring you the best deals. Moreover, our billing is transparent. You do not have to worry about additional or hidden charges. We raise invoices via message or mail.  </p>
                
    </div>
  );
};

export default ServicesOffer2;
