import React from 'react'
import Header from "../../common/componentHeader";
import "./style.scss";
// import { Helmet } from 'react-helmet';

// import Lead from '../Lead';


function Pagenotfound() {


  return (
    <div
      className="h_100 w_100 aboutus_section transitionIn-animation pb_20"
      id="aboutus" >
        
      <div className="head-background">
        <Header secondaryHeading={"Error"} subHeading={"404"} />
      </div>
      <div style={{display:"block", textAlign:"center"}}>
      <p className="aboutus-description" style={{fontSize:"25px"}}>{ "Page not Found!"}</p>
      {/* <p className="primary_heading">{ " Your Booking has been Confirmed. You will get the confirmation mail soon."}</p> */}
      {/* <p className="aboutus-description">{ "Confirmed"}</p> */}

      </div>

      {/* <Lead/> */}
      


    </div>
  )
}

export default Pagenotfound;
