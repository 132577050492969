import React from 'react';
import { CARSDETAILS, SERVICES, WE_OFFERED } from '../../../../constants';
import CardRenderer from '../../../common/cardRenderer';
import Header from '../../../common/componentHeader/index2';
import './style.scss';

const Services = () => {
    return (
        <div className='h_100 services_section transitionIn-animation' id="service">
            <div className='w_80'>
            <h3 class="primary_heading">Are you looking for</h3>
            <h1 class="primary_heading"> Chauffeured Car companies in Perth?</h1>
            <p className='text-content'>Are you visiting the beautiful city of Perth, Australia, for the first time? Or do you want a Chauffeured Car to explore the city and surrounding areas? Let the purpose of your trip be any; we are here to make your travel smooth and hassle-free. We are HR-Chauffeur, one of the leading and renowned companies catering to the diverse traveling needs of people. With our Chauffeured Car services, you can be assured of a memorable trip.</p>
            <p className='text-content'>Irrespective of whether you are looking for a small car or a 7-Seater, we have something for each one of our customers. If you want to hire a Chauffeured Car, is it not better to choose a Chauffeured Car company instead of asking a friend or neighbour for the same? All you have to do is connect with us.  </p>
                <Header
                    heading = {SERVICES}
                    secondaryHeading = {SERVICES}
                    subHeading = {WE_OFFERED}
                />
                <CardRenderer
                    data = { CARSDETAILS }
                />
<h3 class="primary_heading">It’s time to make great memories!</h3>
            <h3></h3>
            <p className='text-content-2'>Several factors play an essential role when it comes to a fun-filled, memorable trip. From selecting the right Chauffeured Car to booking accommodation, your decisions can make a world of difference. Book a private Chauffeured Car in Perth and make your trip hassle-free. Driving through the city and surrounding areas has never been so easy and fun. Our team strives to provide services that can make your visit memorable. Whether you want a car to cover some mileage exploring different parts of Australia or plan to zip around, we have got you covered. </p>
            </div>
        </div>
    );
};

export default Services;