import moment from "moment";
import { useEffect, useState } from "react";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { FORM_HEADER } from "../../../constants";
import { checkIfAirport, checkIfError, isMidnightAndBeforeSix, returnCalculatedPrice } from "../../../utils/commonutils";
import popUpAlert from "../../common/alert";
import { ContactUs } from "../../common/emailSend";
import Loader from "../../common/loader";
import ModalRendererNew from "../../common/modalNew";
import MultiStepFormHeader from "../../common/Stepper";
import Step1 from "./step1";
import Step2 from "./step2";
import Step3 from "./step3";
import Step4 from "./step4";
import "./style.scss";
import { Helmet } from "react-helmet-async";
import { db } from "../../../firebase";
// import firebase from './firebase';
// import { getDatabase, ref, set } from "firebase/database";
const currentDateTime = new Date();





const BookingForm = () => {
  const [step, setStep] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [personalData, setPersonalData] = useState({
    name: { value: "" },
    mobileNumber: { value: "" },
    email: { value: "" },
    hours: { value: 4 },
    pricingType: { value: "airport" },
    date: moment(new Date()).format("MM/DD/YYYY HH:mm"),
    startLocation: {
      value: { name: "", lat: 0, lng: 0 },
      focused: false,
      error: false,
    },
    endLocation: {
      value: { name: "", lat: 0, lng: 0 },
      focused: false,
      error: false,
    },
    flight: { value: "" },
    luggage: { value: 0, focused: false, error: false },
    selectedCar: { value: {}, error: false },
    cost: 0,
    distance: "",
    paymentMethod: "card",
    babySeat: { value: 0 },
    tripOption: "oneWay",
    returnDate: moment(new Date()).format("MM/DD/YYYY HH:mm")
  });
  const [startAddress, setStartAddress] = useState({
    name: "",
    lat: 0,
    lng: 0,
  });
  const [endAddress, setEndAddress] = useState({ name: "", lat: 0, lng: 0 });

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);
    return () => clearTimeout(timer);
  });





  const handleChange = (e, error) => {
    const { name, value } = e.target;
    if (name === "tripOption") {
      setPersonalData((personalData) => ({
        ...personalData,
        [name]: value,
      }));
    } else {
      let updatedValue = personalData[name];
      updatedValue.value = value;
      updatedValue.focused = true;
      updatedValue.error = error;
      setPersonalData((personalData) => ({
        ...personalData,
        [name]: updatedValue,
      }));
    }
  };

  const handlePricingChange = (value) => {
    let updatedValue = personalData["pricingType"];
    updatedValue.value = value;
    setPersonalData((personalData) => ({
      ...personalData,
      pricingType: updatedValue,
      tripOption:
        value === "wineTour" || value === "asDirected"
          ? "hourly"
          : value !== "weddingTransfer" &&
            personalData["tripOption"] === "hourly"
            ? "oneWay"
            : personalData["tripOption"],
    }));
  };

  const handleSelectStart = (address) => {
    let updatedStartLocation = personalData.startLocation;
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        let startObj = latLng;
        startObj["name"] = address;
        updatedStartLocation.value = startObj;
        updatedStartLocation.focused = true;
        if (address.length > 0) {
          updatedStartLocation.error = false;
        }
        setStartAddress(startObj);
        setPersonalData((personalData) => ({
          ...personalData,
          startLocation: updatedStartLocation,
        }));
      })
      .catch((error) => console.error("Error", error));
  };

  const handleSelectEnd = (address) => {
    let updatedEndLocation = personalData.endLocation;

    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        let startObj = latLng;
        startObj["name"] = address;
        updatedEndLocation.value = startObj;
        updatedEndLocation.focused = true;
        if (address.length > 0) {
          updatedEndLocation.error = false;
        }
        setEndAddress(startObj);
        setPersonalData((personalData) => ({
          ...personalData,
          endLocation: updatedEndLocation,
        }));
      })
      .catch((error) => console.error("Error", error));
  };

  const selectCard = (selectedCard, key) => {
    if (key === "paymentMethod") {
      setPersonalData((personalData) => ({
        ...personalData,
        [key]: selectedCard.value,
      }));
    } else {
      let update = personalData["selectedCar"];
      update.value = selectedCard;
      update.error = false;
      setPersonalData((personalData) => ({
        ...personalData,
        [key]: update,
      }));
    }
  };

  async function calculateDistance() {
    let google = window.google;
    let directionsService = new google.maps.DirectionsService();
    let startLocation = personalData?.startLocation.value;
    let endLocation = personalData?.endLocation.value;
    let obj = {};
    // var distance = 0;
    // let mk1 = new google.maps.Marker({ position: startLocation });
    // let mk2 = new google.maps.Marker({ position: endLocation });
    const route = {
      origin: startLocation,
      destination: endLocation,
      travelMode: "DRIVING",
    };

    await directionsService.route(route, function (response, status) {
      if (status !== "OK") {
        window.alert("Directions request failed due to " + status);
        return;
      } else {
        var directionsData = response.routes[0].legs[0]; // Get data about the mapped route
        if (!directionsData) {
          window.alert("Directions request failed");
          return;
        } else {
          obj.wayPoints = response.geocoded_waypoints;
          obj.distance = directionsData.distance.value / 1000;
          obj.duration = directionsData.duration.text;
        }
      }
    });
    return obj;
  }

  const calculatePricing = async () => {
    let returnData = 0;
    let googleObj = await calculateDistance();
    returnData = returnCalculatedPrice(personalData, googleObj.distance);
    // if (personalData["pricingType"].value === "airport") {
    //   if (googleObj.distance > 20) {
    //     cost =
    //       personalData["selectedCar"]?.value?.airportRates +
    //       (googleObj.distance - 20) * 3;
    //   } else {
    //     cost = personalData["selectedCar"]?.value?.airportRates;
    //   }
    // } else if (personalData["pricingType"]?.value === "weddingTransfer") {
    //   cost =
    //     personalData["selectedCar"]?.value?.hourlyRate *
    //     1.5 *
    //     personalData["hours"]?.value;
    // } else {
    //   cost =
    //     personalData["selectedCar"]?.value?.hourlyRate *
    //     personalData["hours"]?.value;
    // }
    if (personalData["babySeat"] > 0) {
      returnData = returnData + (personalData["babySeat"] === 1 ? 20 : 30);
    }
    setPersonalData((personalData) => ({
      ...personalData,
      distance: `${googleObj.distance.toFixed(0)} KM`,
      duration: googleObj.duration,
      cost: returnData.toFixed(0),
    }));
  };

  console.log("Pers", personalData.cost)

  // const formattedDateTime = currentDateTime.toISOString();
  function formatDateTime(date) {
    const dd = String(date.getDate()).padStart(2, '0');
    const mm = String(date.getMonth() + 1).padStart(2, '0'); // January is 0!
    const yyyy = date.getFullYear();
    const hh = String(date.getHours()).padStart(2, '0');
    const min = String(date.getMinutes()).padStart(2, '0');
  
    return `${mm}/${dd}/${yyyy} ${hh}:${min}`;
  }
  
  const currentDateTime = new Date();
  const formattedDateTime = formatDateTime(currentDateTime);
  console.log(formattedDateTime);




  
  // const storeDataInFirestore = async (startAddress) => {

  //   try {
  //     // console.log(startAddress)
  //     db.collection("Chauffeur88").add(personalData);
  //     console.log('Data stored in Firestore successfully.');
  //     return true; // Indicates successful storage
  //   } catch (error) {
  //     console.error('Error storing data in Firestore:', error);
  //     return false; // Indicates an error occurred
  //   }
  // };

  const StepRenderer = () => {
    switch (step) {
      case 1:
        return (
          <Step1
            personalData={personalData}
            setPersonalData={(val) => setPersonalData(val)}
            handleChange={handleChange}
            handleSelectStart={handleSelectStart}
            setStartAddress={(val) => setStartAddress(val)}
            startAddress={startAddress}
            handleSelectEnd={handleSelectEnd}
            endAddress={endAddress}
            setEndAddress={(val) => setEndAddress(val)}
            handlePricingChange={handlePricingChange}
            setCount={(val) => {
              setPersonalData((personalData) => ({
                ...personalData,
                babySeat: val,
              }));
            }}
          />
        );
      case 2:
        return (
          <Step2
            personalData={personalData}
            setPersonalData={(val) => setPersonalData(val)}
            handleChange={handleChange}
            selectCard={selectCard}
          />
        );
      case 3:
        return (
          <Step3
            personalData={personalData}
            handleChange={handleChange}
            selectCard={selectCard}
          />
        );

      case 4:
        return <Step4 personalData={personalData} selectCard={selectCard} />;
      default:
        return (
          <button
            className="fill_btn rs-btn rs-btn-subtle"
            onClick={() => {
              setStep(1);
            }}
          >
            Book Now
          </button>
        );
    }
  };

  const HandleStep = async (isTrue) => {
    let getObj = checkIfError(personalData, step, startAddress, endAddress);
    let checkAirportCondition =
      personalData["startLocation"].value.name.length > 0 &&
      startAddress.lat !== 0 &&
      endAddress.lat !== 0 &&
      personalData["endLocation"].value.name.length > 0 &&
      personalData["pricingType"].value === "airport";

    if (
      checkAirportCondition &&
      !(
        personalData["endLocation"].value.name
          .toLowerCase()
          .includes("airport") ||
        personalData["startLocation"].value.name
          .toLowerCase()
          .includes("airport")
      )
    ) {
      popUpAlert(
        "error",
        "Either Start Or End Location Should Be Airport. You May Also Choose Different Pricing Type.",
        "Location Error"
      );
    } else if (getObj.error) {
      setPersonalData((personalData) => ({
        ...personalData,
        ...getObj.personalData,
      }));
    } else {
      if (step === 2) {
        await calculatePricing();
        setStep(step + 1);
      } else if (step === 3) {
        if (isTrue) {
        } else {
          // popUpAlert(
          //   "success",
          //   "You Booking has been Confirmed. You will get the confirmation mail soon.",
          //   "Confirmed"
          // );
          let emailParam = {
            email: personalData.email.value,
            mobile: personalData.mobileNumber.value,
            pricingType:
              personalData["pricingType"].value === "airport"
                ? "Airport Transport"
                : "Hourly",
            date: personalData.date,
            startLocation: personalData.startLocation.value.name,
            endLocation: personalData.endLocation.value.name,
            price: personalData.cost,
            car: personalData["selectedCar"].value.label || "",
            name: personalData.name.value,
            subject: "",
            paymentMethod: "Car eftpos",
            babySeats: personalData.babySeat.value,
            bcc: "hr.chauffeurservice@gmail.com",
            
            luggage: personalData?.luggage?.value,
            // timestamp: firebase.firestore.FieldValue.serverTimestamp(),
            currentDateTime: formattedDateTime,
            isVisible:1,
          };
          if (personalData && personalData["tripOption"] === "hourly") {
            emailParam.hours = personalData?.["hours"]?.value;
          }

          ContactUs(emailParam, "client_template");

          ContactUs(emailParam, "our_template");

          

          try {
            // console.log(startAddress)
            db.collection("Chauffeur88").add(emailParam);
            console.log('Data stored in Firestore successfully.');
            setTimeout(() => {
              window.location.pathname = "/thankyou";
            }, 1000);
            return ; // Indicates successful storage
          } catch (error) {
            console.error('Error storing data in Firestore:', error);
            return false; // Indicates an error occurred
          }

        }
      } else {
        setStep(step + 1);
      }
    }
  };

  const returnDisabledAndMessage = () => {
    let returnObj = {};
    if (
      personalData["mobileNumber"].error ||
      personalData["name"].error ||
      personalData["email"].error
    ) {
      returnObj.error = true;
      if (
        personalData["mobileNumber"].value.length === 0 ||
        personalData["name"].value.length === 0 ||
        personalData["email"].value.length === 0
      ) {
        returnObj.message = "Please fill All the Fields";
      } else {
        returnObj.message = "Please validate All the Fields";
      }
    }

    return returnObj;
  };

  return (
    <>
      <Helmet>
        <title>Book Premium Chauffeur from HR Chauffeur in Perth</title>
        <meta name="description" content="Book from our luxury Chauffeur cars service at Perth HR Chauffeur Hire. Divers available 24/7 for airport, corporate & wedding transfers across Perth."/>
        <link rel="canonical" href="https://www.hr-chauffeur.com.au/book-chauffeur" />
      </Helmet>
      <div className="booking-wrapper h_100">
        {isLoading && <Loader />}
        <div className="main-form-wrapper h_100 d-flex flex-column">
          <MultiStepFormHeader step={step} steps={FORM_HEADER} />
          <div
            className={
              isLoading ? "booking-form" : "booking-form transitionIn-animation"
            }
          >
            {/* <button
              className="nxt-btn mr-15"
              onClick={() => {
                storeDataInFirestore();
              }}
            >
              Database
            </button> */}
            {StepRenderer()}
            <div className="buttons-wrapper">
              {step > 1 && (
                <button
                  className="back-btn"
                  onClick={() => {
                    step > 1 && setStep(step - 1);
                  }}
                >
                  Back
                </button>
              )}
              {step === 3 && (
                <button
                  className="nxt-btn mr-15"
                  onClick={() => {
                    HandleStep();
                  }}
                >
                  Pay Later
                </button>
              )}
              {step < 3 && (
                <button
                  className="nxt-btn"
                  onClick={() => {
                    HandleStep();
                  }}
                >
                  Next
                </button>
              )}
              {step === 3 && (
                <ModalRendererNew
                  buttonName="Pay Now"
                  modalTitle="Online Payment"
                  // renderHTML={}
                  personalData={personalData}
                  disabled={returnDisabledAndMessage().error}
                  tooltipMessage={returnDisabledAndMessage().message}
                />
              )}
              {/* {openModal()} */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BookingForm;