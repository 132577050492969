import React from "react";
import "./style.scss";
// import DoneIcon from '@mui/icons-material/Done';
import { ReactComponent as RightIcon } from '../../../assets/images/icons/icon_right.svg';
import CheckIcon from '@rsuite/icons/Check';

class MultiStepFormHeader extends React.Component {
  render() {
    const { steps, step } = this.props;
    return (
      <div className="center_justify">
        <div>
          <div className="header_form d-flex">
            {steps.map((item, index) => {
              return (
                <div
                  id={step === item.step ? "active" : ""}
                  className={`${
                    step > item.step ? "passed" : ""
                  } d-flex step_wrapper`}
                >
                  <div className="step">
                    <p>{step > item.step ? <CheckIcon /> : <p>{item.step}</p>}</p>
                  </div>
                  <div className="step_label">
                    <p>{item.value}</p>
                  </div>
                  <div className="right_arrow_container">
                    {steps.length - 1 != index && <RightIcon/>}
                    {/* <img src = {RightIcon} alt = "right-arrow"/> */}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default MultiStepFormHeader;
