import {
  JOURNEY_OBJECT_FIRST,
  JOURNEY_OBJECT_SECOND,
  JOURNEY_OBJECT_THIRD,
  JOURNEY_OBJECT_THIRD_HOURLY,
  personalDataInputs,
} from "../../../../constants";
import CustomInput from "../../../common/customInput";
import DetailsBox from "../../../common/DetailsBox";
import "./style.scss";

const Step3 = (props) => {
  const { personalData, handleChange } = props;

  console.log(personalData)

  return (
    <div className="step3-wrapper">
      <div className="step3-left">
        <p className="steps-heading">Your Journey Details</p>
        <DetailsBox
          personalData={personalData}
          firstSection={JOURNEY_OBJECT_FIRST}
          secondSection={JOURNEY_OBJECT_SECOND}
          thirdSection={personalData["pricingType"].value === "hourly" ? JOURNEY_OBJECT_THIRD_HOURLY : JOURNEY_OBJECT_THIRD}
        />
      </div>
      <div className="step3-right">
        <p className="steps-heading">Personal Details</p>
        <div className="step3-form-wrapper">
        {personalDataInputs.map((item) => {
          return (
            <div className="input-container">
              <p>{item.label}</p>
              <CustomInput
                onChange={handleChange}
                value={personalData[item.name]}
                name={item?.name}
                placeholder={item?.placeholder}
                type={item?.type}
                regex={item?.regex}
                inputName={item.label}
              />
            </div>
          );
        })}
        </div>
      </div>
    </div>
  );
};

export default Step3;
