import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import styles from './DemoCarousel.module.css'; // Import a separate CSS module for styling
import Header from "../../common/componentHeader/index2";


function DemoCarousel({ testimonials }) {
    return (
        <div className="h_100 services_section transitionIn-animation pb_20" id="howitworks">
            <div className="w_80">
                <Header heading="Testimonials" secondaryHeading="HAPPY" subHeading="CLIENTS"/>
                <Carousel autoPlay infiniteLoop>
                    {testimonials && testimonials.map((testimonial, index) => (
                        <div key={index} className={styles.carouselItem}>
                            {/* <img src={Image} alt='' className={styles.carouselImage} /> */}
                            <div className={styles.legendContainer}>
                                <h3 className={styles.comment}>{testimonial.comment}</h3>
                            </div>
                            <h4 className={styles.clientName}>"{testimonial.name}"</h4>
                        </div>
                    ))}
                </Carousel>
            </div>
        </div>

    );
}

export default DemoCarousel;
