import React from 'react';
import './style.css';
import { HIRE } from '../../../constants';
import { Link } from "react-router-dom";

function Bookbanner() {

  return (
    <div className='bookbanner'>
        
        <Link
                name={HIRE}
                className="outline_btn hire_btn hire_btn_card"
                to="/book-chauffeur"
                style={{minWidth:'250px', textAlign:'center'}}
              >
                {HIRE}
        </Link>
    </div>
  )
}

export default Bookbanner;
