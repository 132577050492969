import moment from "moment";
import { useState } from "react";
import { SelectPicker } from "rsuite";
import {
  QUOTATION_FORM_INPUTS,
  TRIPOPTION_OBJECT,
  TRIPOPTION_SPECIFIC,
} from "../../../constants";
// import CustomInput from "../customInput";
import DateRange from "../dateRangePicker";
// import { ContactUs } from "../emailSend";
import "./style.scss";
import LocationSearchInput from "../GoogleMap";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
// import LocationIcon from "@rsuite/icons/Location";
import popUpAlert from "../alert";
import {
  calculateDistance,
  checkIfValid,
  ifAirportLocationValidation,
  returnCalculatedPrice,
} from "../../../utils/commonutils";
import QuoteModal from "./quoteModal";




const QuotationForm = (props) => {


  function formatDateTime(date) {
    const dd = String(date.getDate()).padStart(2, '0');
    const mm = String(date.getMonth() + 1).padStart(2, '0'); // January is 0!
    const yyyy = date.getFullYear();
    const hh = String(date.getHours()).padStart(2, '0');
    const min = String(date.getMinutes()).padStart(2, '0');
  
    return `${mm}/${dd}/${yyyy} ${hh}:${min}`;
  }
  
  const currentDateTime = new Date();
  const formattedDateTime = formatDateTime(currentDateTime);


  


  const [formData, setFormData] = useState({
    startLocation: { name: "", lat: 0, lng: 0 },
    endLocation: { name: "", lat: 0, lng: 0 },
    date: moment(new Date()).format("MM/DD/YYYY hh:mm A"),
    transferType: "",
    carType: "",
    tripOption: "",
    hours: 0,
    name:"",
    phone:"",
    currentDateTime: formattedDateTime,
    email:"",
    isVisible:1,
   
  });


  
  const [address, setAddress] = useState({
    startAddress: { name: "", lat: 0, lng: 0 },
    endAddress: { name: "", lat: 0, lng: 0 },
  });

  // const CurrentLocation = () => {
  //   navigator.geolocation.getCurrentPosition(function (position) {
  //     fetch(
  //       `https://maps.googleapis.com/maps/api/geocode/json?latlng=${position.coords.latitude},${position.coords.longitude}&key=AIzaSyBO1EhOYGsrG7Q7h7ubIIsvdMmpuHCY2YA`
  //     )
  //       .then((res) => res.json())
  //       .then(
  //         (result) => {
  //           setAddress((address) => ({
  //             ...address,
  //             startAddress: {
  //               name: result.results[0].formatted_address,
  //               lat: position.coords.latitude,
  //               lng: position.coords.longitude,
  //             },
  //           }));
  //           setFormData((personalData) => ({
  //             ...personalData,
  //             startLocation: {
  //               name: result.results[0].formatted_address,
  //               lat: position.coords.latitude,
  //               lng: position.coords.longitude,
  //             },
  //           }));
  //         },

  //         (error) => {
  //           // this.setState({
  //           // isLoaded: true,
  //           // error
  //           // });
  //         }
  //       );
  //   });
  // };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((personalData) => ({
      ...personalData,
      [name]: value,
    }));
  };


  const handleSelectLocation = (
    type = "startLocation",
    receivedAddress,
    getAddress
  ) => {
    let updatedLocation = formData[type];

    geocodeByAddress(receivedAddress)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        let startObj = latLng;
        startObj["name"] = receivedAddress;
        updatedLocation = startObj;
        setAddress((address) => ({
          ...address,
          [getAddress]: startObj,
        }));
        setFormData((formData) => ({
          ...formData,
          [type]: updatedLocation,
        }));
      })
      .catch((error) => console.error("Error", error));
  };

  const ReturnInputType = (item) => {
    switch (item.type) {
      case "date":
        return (
          <DateRange
            date={formData["date"]}
            setDate={(picker) =>
              setFormData((formData) => ({
                ...formData,
                date: moment(picker).toDate(),
              }))
            }
            format="DD/MM/YYYY HH:mm A"
          />
        );
      case "dropdown":
        return (
          <SelectPicker
            placeholder={item.placeholder}
            name={item.name}
            onChange={(value) => {
              setFormData((formData) => ({
                ...formData,
                [item.name]: value,
              }));
              if (item?.name === "transferType") {
                setFormData((formData) => ({
                  ...formData,
                  tripOption: TRIPOPTION_SPECIFIC[value][0],
                }));
              }
            }}
            // value={formData[item.name]}
            data={item.data}
            cleanable={false}
            style={{ width: 180 }}
          />
        );
      case "location":
        return (
          <LocationSearchInput
            handleSelect={(address) =>
              handleSelectLocation(item?.name, address, item?.address)
            }
            placeHolder={item?.placeholder}
            personalData={formData[item?.name]}
            address={address[item?.address]}
            handleChange={(getAddress) => {
              setAddress((address) => ({
                ...address,
                [item?.address]: { name: getAddress, lat: 0, lng: 0 },
              }));
            }}
          />
        );
      case "radio":
        return (
          <div className="radio-container" onChange={handleChange}>
            {formData["transferType"].length > 0 &&
              TRIPOPTION_SPECIFIC[formData["transferType"]].map((item) => {
                return (
                  <label>
                    <input
                      type="radio"
                      value={item}
                      name="tripOption"
                      checked={formData["tripOption"] === item}
                    />{" "}
                    {TRIPOPTION_OBJECT[item]}
                  </label>
                );
              })}
          </div>
        );
      default:
        return (
          <>
            <input
              onChange={handleChange}
              name={item?.name}
              placeholder={item?.placeholder}
              type={item?.type}
              regex={item?.regex}
              inputName={item.label}
              // value={formData[item.name]}
              onBlur={handleChange}
            />
          </>
        );
    }
  };
  return (
    <div className="quotation-form-wrapper">
      <div className="form-heading">
        <p className="heading">FREE QUOTE</p>
        <span className="heading-description">
          Get A Response In Minutes We look forward to assisting!
        </span>
      </div>
      <div className="form">
        <table>
          {QUOTATION_FORM_INPUTS.map((item) => {
            return (
              <div
                className={
                  item?.type === "radio"
                    ? "input-radio-container"
                    : "quotation-input-container"
                }
              >
                <tr>
                  <td>
                    <p className="label">{item.label}</p>
                  </td>
                  <td>{ReturnInputType(item)}</td>
                </tr>
              </div>
            );
          })}
        </table>
        <div className="m-auto">
          {/* <button type="button" className="nxt-btn"> */}
            <QuoteModal
              formData={formData}
              address={address}
            />
          {/* </button> */}
        </div>
      </div>
    </div>
  );
};

export default QuotationForm;
