import "./style.scss";

const CustomInput = (props) => {
  const {
    onChange,
    value,
    name,
    placeholder,
    type,
    regex,
    errorMessage,
    inputName,
  } = props;


  const handleChange = (e) => {
    const { value } = e.target;
    let regexValue = true;
    if (regex) {
      regexValue = !!value.match(regex);
    }
    onChange(e, !regexValue);
  };

  return (
    <div>
      <input
        onChange={handleChange}
        value={value.value}
        name={name}
        placeHolder={placeholder}
        type={type}
        onBlur={handleChange}
        className={value?.error && value.focused ? "error-box" : ""}
      />

      <p className={value?.error && value.focused ? "error-text" : "v_hidden"}>
        {!!errorMessage?.length
          ? errorMessage
          : value.value.length > 0
          ? `Please Enter a Valid ${inputName}`
          : "This Field is Required"}
      </p>
    </div>
  );
};

export default CustomInput;