import React, { Children, useState } from "react";
import { Modal, ButtonToolbar, Button, Loader } from "rsuite";
import Payment from "../../../Payment";
import "./style.scss";

const ModalRendererNew = ({
  buttonName,
  personalData,
  modalTitle,
  disabled,
  tooltipMessage,
}) => {
  const [open, setOpen] = useState(false);
  // const [rows, setRows] = useState(0);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setClickCount(0);
  };
  const [clickCount, setClickCount] = useState(0);

  // const handleEntered = () => {
  //   setTimeout(() => setRows(80), 2000);
  // };

  const handleSubmit = async () => {
    if(window.confirm("Confirm Payment") === true) {
      setClickCount(clickCount+1);
    }
  };

  return (
    <>
      <ButtonToolbar className="position-rel">
        <Button onClick={handleOpen} disabled={disabled} className="nxt-btn">
          {buttonName}
        </Button>
        {disabled && (
          <div className="tooltip">
            <p>{tooltipMessage}</p>
          </div>
        )}
      </ButtonToolbar>

      <Modal
        open={open}
        onClose={handleClose}
        // onEntered={handleEntered}
        // onExited={() => {
        //   setRows(0);
        // }}
      >
        <Modal.Header>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Payment
            clicked={clickCount}
            data={personalData}
            close={handleClose}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleSubmit} className="nxt-btn">
            Pay
          </Button>
          <Button onClick={handleClose} appearance="subtle">
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalRendererNew;
