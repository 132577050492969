import "./style.scss";
import Logo from "../../../assets/images/HR-Logo.png";
import { Link } from "react-router-dom";

function SecondaryFooter(props) {
  return (
    <div className="secondary_footer">
      <footer class="footer-distributed">
        <div class="footer-left">
          <div className="footer_logo_wrapper">
            <img src={Logo} className="logo" alt="HR Chauffeur Service" />
          </div>
        </div>

        <div class="footer-center">
          <div>
            <i class="fa fa-map-marker"></i>
            <p>
              {/* <span>444 S. Cedros Ave</span> */}
              Perth, Australia
            </p>
          </div>

          <div>
            <i class="fa fa-phone"></i>
            <p>
              <a onclick="return gtag_report_conversion('tel:+61455031641');" href="tel:+61455031641" >+61 455031641</a>
            </p>
          </div>
          <div>
            <i class="fa fa-envelope"></i>
            <Link className="lightseagreen-link" to="terms-and-conditions">
              Terms and Conditions
            </Link>
          </div>

          <div>
            <i class="fa fa-envelope"></i>
            <p>
              <a href="mailto:hr.chauffeurservice@gmail.com">
                hr.chauffeurservice@gmail.com
              </a>
            </p>
          </div>
        </div>

        <div class="footer-right">
          <p class="footer-company-about">
            <span>About HR-Chauffeur</span>
            We are a premier provider of luxury transportation services, serving
            clients throughout perth, Australia. Our team of experienced and
            professional chauffeurs is dedicated to providing you with a safe,
            comfortable, and reliable travel experience.
          </p>

          {/* <div class="footer-icons">
            <a href="#">
              <i class="fa fa-facebook"></i>
            </a>
            <a href="#">
              <i class="fa fa-twitter"></i>
            </a>
            <a href="#">
              <i class="fa fa-linkedin"></i>
            </a>
            <a href="#">
              <i class="fa fa-github"></i>
            </a>
          </div> */}
        </div>

        <div class="footer-right center">
          <p class="footer-company-about">
            <span>Links</span>
          </p>

          <div class="footer-link">
            <Link to="/">Home</Link>
            <Link to="aboutus">About Us</Link>
            <Link to="services">Services</Link>
            <Link to="advantages">Advantages</Link>
            <Link to="book-chauffeur">Hire Now</Link>
          </div>
        </div>
        <div class="footer-right left">
          <p class="footer-company-about">
            <span>Services</span>
          </p>

          <div class="footer-link">
            <Link to="/airporttransport">Airport Transfer</Link>
            <Link to="/corporatetransport">Corporate Transport</Link>
            <Link to="/p2ptransport">P2P Transport</Link>
            <Link to="/hourlytransport">Hourly Transport</Link>
            <Link to="/citytransport">City Transport</Link>
            <Link to="/luxurytransport">Luxury Transport</Link>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default SecondaryFooter;
