import Container from 'react-bootstrap/Container';
import "./style.scss";
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Logo from "../../../assets/images/HR-Logo.png";
import MessageIcon from "@rsuite/icons/Message";
import { Button } from "rsuite";
import { Link } from "react-router-dom";


function CollapsibleExample({ onSelect, activeKey, items, ...props }) {
  return (
    <Navbar fixed="top" collapseOnSelect expand="lg" className="bg-body-tertiary" {...props}>
      <Container>
        <Navbar.Brand as={Link} to="/"><img src={Logo} className="logo" alt="HR Chauffeur Logo" /></Navbar.Brand>

        <Button className='mobilecall' appearance="ghost" href="tel:+61455031641" onclick="return gtag_report_conversion('tel:+61455031641');">
          CALL +61 455031641
          {/* <MessageIcon className="text_Icon" /> */}
        </Button>

        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto" >
            <Nav.Link href="#" as={Link} to="/">Home</Nav.Link>
            <Nav.Link href="#" as={Link} to="/aboutus">About Us</Nav.Link>
            <Nav.Link href="#" as={Link} to="/services">Services</Nav.Link>
            <Nav.Link href="#" as={Link} to="/advantages">Advantages</Nav.Link>
            <Nav.Link href="#" as={Link} to="/book-chauffeur">Hire Now</Nav.Link>
          </Nav>
          <Nav pullRight>
            <Nav.Item

              href="tel:+61455031641"
            // target="_blank"
            >
              <Button appearance="ghost" href="tel:+61455031641" onclick="return gtag_report_conversion('tel:+61455031641');">
              +61 455031641
                <MessageIcon className="text_Icon" />
              </Button>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default CollapsibleExample;